import { DomainEntity, SgvId, SgvJson } from '@financial/arch';

export class CostCenter implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public name = '',
    public code = '',
    public readonly active = true
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): CostCenter {
    return json ? SgvJson.from.simple(json, CostCenter) : null;
  }
}
