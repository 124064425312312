import { Injectable } from '@angular/core';
import { ApiService, TemporalRepositoryImpl } from '@financial/arch';
import { ClientsAccountingBalance } from '.';

@Injectable({ providedIn: 'root' })
export class ClientsAccountingBalanceRepository extends TemporalRepositoryImpl<ClientsAccountingBalance, ClientsAccountingBalance> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/clientsAccountingBalance'), ClientsAccountingBalance, ClientsAccountingBalance);
  }

}
