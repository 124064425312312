import { Component, Inject, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormControl,
  FormBuilder,
  ValidatorFn,
  AbstractControlOptions,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-input-text-dialog',
  templateUrl: './input-text-dialog.component.html',
  styleUrls: ['./input-text-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTextDialogComponent implements OnInit {
  private _defaultData: InputTextDialogData = {
    title: '',
    content: '',
    label: '',
    value: '',
    minLength: 0,
    maxLength: 256,
    required: true,
    pattern: '',
    type: 'input',
    minRows: 3,
    maxRows: 10
  };

  data: InputTextDialogData;

  inputTextControl: FormControl;

  constructor(
    formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: InputTextDialogData,
    private dialogRef: MatDialogRef<InputTextDialogComponent>
  ) {
    this.dialogRef.disableClose = true;
    this.data = (<any>Object).assign({}, this._defaultData, data);
    this.inputTextControl = formBuilder.control(data.value, this.buildValidators());
  }

  ngOnInit() {}

  get isTextArea(): boolean {
    return this.data.type === 'textarea';
  }

  private buildValidators(): ValidatorFn | ValidatorFn[] | AbstractControlOptions | null {
    return [
      Validators.minLength(this.data.minLength),
      Validators.maxLength(this.data.maxLength),
      Validators.pattern(this.data.pattern)
    ].filter(it => it != null);
  }
}

export interface InputTextDialogData {
  title: string;
  content: string;
  label?: string;
  value?: string;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  pattern?: string;
  type?: 'textarea' | 'input';
  minRows?: number;
  maxRows?: number;
}
