import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Actives, Filter, FilterOp, SortMode, SortOrder } from '@financial/arch';
import { debounceTime, distinct } from 'rxjs/operators';
import { EntityListPerspective, SimpleEntityListPerspective } from './entity-list-perspective';
import { FilterDescription, FilterType } from './filter-description';
import { SortDescription } from './sort-description';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'entity-list-perspective',
  templateUrl: './entity-list-perspective.component.html',
  styleUrls: ['./entity-list-perspective.component.scss']
})
export class EntityListPerspectiveComponent implements OnInit {
  @Input() allowedFilters: FilterDescription[] = [];

  @Input() allowedSorts: SortDescription[] = [];

  @Input() showActives = true;

  @Output() valueChange = new EventEmitter<EntityListPerspective>();

  form: FormGroup;

  private _value: EntityListPerspective;

  activesOptions = [
    { name: 'Ativos', value: Actives.TRUE },
    { name: 'Inativos', value: Actives.FALSE },
    { name: 'Todos', value: Actives.ALL }
  ];

  operations = {
    text: [
      { name: 'Contendo', value: FilterOp.CONT },
      { name: 'Igual a', value: FilterOp.EQ },
      { name: 'Inciando com', value: FilterOp.SW },
      { name: 'Terminando com', value: FilterOp.EW },
      { name: 'Diferente de', value: FilterOp.NE }
    ],
    number: [
      { name: 'Igual a', value: FilterOp.EQ },
      { name: 'Maior que', value: FilterOp.GT },
      { name: 'Menor que', value: FilterOp.LT },
      { name: 'Diferente de', value: FilterOp.NE }
    ],
    reference: [
      { name: 'Igual a', value: FilterOp.EQ },
      { name: 'Diferente de', value: FilterOp.NE }
    ],
    enum: [{ name: 'Igual a', value: FilterOp.EQ }, { name: 'Diferente de', value: FilterOp.NE }]
  };

  constructor(private fb: FormBuilder) {
    this.form = fb.group({
      filters: fb.array([]),
      sorts: fb.array([]),
      settings: fb.group({
        actives: fb.control(Actives.TRUE)
      })
    });

    this.form.valueChanges
      .pipe(
        distinct(),
        debounceTime(400)
      )
      .subscribe(v => {
        this._value = new SimpleEntityListPerspective(
          '',
          this.filters.controls
            .filter(c => c.valid)
            .map(c => c.value)
            .map(c => new Filter(c.filter.field, c.operation, c.value)),
          this.sorts.controls
            .filter(c => c.valid)
            .map(c => c.value)
            .map(c => new SortOrder(c.field.field, c.value)),
          this.settings.value.actives
        );
        this.valueChange.emit(this._value);
        if (this.filters.valid) {
          this.pushEmptyFilter();
        }
        if (this.sorts.valid) {
          this.pushEmptySort();
        }
      });

    this.value = new SimpleEntityListPerspective();
  }

  get value() {
    return this._value;
  }

  @Input()
  set value(value: EntityListPerspective) {
    if (this._value !== value) {
      this._value = value;
      this.publishValueToForm(value);
    }
  }

  get filters() {
    return this.form.get('filters') as FormArray;
  }

  get sorts() {
    return this.form.get('sorts') as FormArray;
  }

  get settings() {
    return this.form.get('settings') as FormGroup;
  }

  get showSettings() {
    return this.showActives;
  }

  ngOnInit() { }

  isText(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.TEXTUAL);
  }

  isNumeric(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.NUMERIC);
  }

  isBoolean(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.BOOLEAN);
  }

  isDate(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.DATE);
  }

  isDateTime(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.DATE_TIME);
  }

  isReference(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.REFERENCE);
  }

  isEnum(group: FormGroup) {
    return this.isOfType(group.value.filter as FilterDescription, FilterType.ENUM);
  }

  isOfType(value: FilterDescription, type: FilterType) {
    return value ? value.type === type : false;
  }

  onFilterChange(group: FormGroup) {
    const filter = group.get('filter');
    if (filter.valid) {
      let value = {};
      switch (filter.value.type) {
        case FilterType.TEXTUAL:
          value = {
            operation: this.operations.text[0].value,
            value: ''
          };
          break;

        case FilterType.NUMERIC:
          value = {
            operation: this.operations.number[0].value,
            value: null
          };
          break;

        case FilterType.BOOLEAN:
          value = {
            operation: FilterOp.EQ,
            value: true
          };
          break;

        case FilterType.REFERENCE:
          value = {
            operation: FilterOp.EQ,
            value: null
          };
          break;

        case FilterType.ENUM:
          value = {
            operation: FilterOp.EQ,
            value: null
          };
          break;
      }
      group.patchValue(value);
    }
  }

  removeFilter(position: number) {
    this.filters.removeAt(position);
  }

  removeSort(position: number) {
    this.sorts.removeAt(position);
  }

  private publishValueToForm(value: EntityListPerspective) {
    this.form.setValue({
      filters: value.filters.map(f => this.filterToControl(f)).filter(f => f !== null),
      sorts: value.sorts.map(s => this.sortToControl(s)).filter(s => s !== null),
      settings: {
        actives: value.actives
      }
    });
  }

  private filterToControl(filter: Filter) {
    const allowedFilter = this.allowedFilters.filter(af => af.field === filter.field);
    if (allowedFilter !== null) {
      const group = this.fb.group({
        filter: this.fb.control(allowedFilter, [Validators.required]),
        operation: this.fb.control(FilterOp.EQ, [Validators.required]),
        value: this.fb.control(null, [Validators.required])
      });
      return group;
    } else {
      return null;
    }
  }
  private sortToControl(filter: SortOrder) {
    const allowedSort = this.allowedSorts.filter(af => af.field === filter.field);
    if (allowedSort !== null) {
      const group = this.fb.group({
        field: this.fb.control(allowedSort, [Validators.required]),
        value: this.fb.control(SortMode.ASC, [Validators.required])
      });
      return group;
    } else {
      return null;
    }
  }

  private pushEmptyFilter() {
    this.filters.push(
      this.fb.group({
        filter: this.fb.control(null, [Validators.required]),
        operation: this.fb.control(null, [Validators.required]),
        value: this.fb.control(null, [Validators.required])
      })
    );
  }

  private pushEmptySort() {
    this.sorts.push(
      this.fb.group({
        field: this.fb.control(null, [Validators.required]),
        value: this.fb.control(SortMode.ASC, [Validators.required])
      })
    );
  }
}
