import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { ChartOfAccount, Provider, ProvidersAccountingPayment } from "@financial/domain";

export class ProvidersAccountingBalance implements DomainEntity {

  constructor(
    public readonly id = SgvId.gen(),
    public readonly provider : Provider = new Provider(),
    public readonly chartOfAccount: ChartOfAccount = null,
    public readonly payments: ProvidersAccountingPayment[] = [],
    public readonly totalDebits: number = 0,
    public readonly totalBalance: number = 0,
    public readonly previousBalance: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): ProvidersAccountingBalance {
    return data ? SgvJson.from.simple(data, ProvidersAccountingBalance) : null;
  }
}
