import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Provider, ProviderSummary } from './provider';

@Injectable({ providedIn: 'root' })
export class ProvidersRepository extends RepositoryImpl<ProviderSummary, Provider> {
  constructor(api: ApiService) {
    super(api.root.path('providers'), ProviderSummary, Provider);
  }
}
