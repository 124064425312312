import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorResponse, HttpErrorHandler } from './handlers/http-error-handler';
import { InterceptorConfig } from './interceptors.config';

export const INTERCEPT_ERROR_RESPONSE_HEADER = 'InterceptErrorResponse';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  private handlers: HttpErrorHandler[] = [];

  constructor(@Inject(HttpErrorHandler) @Optional() handlers: HttpErrorHandler[]) {
    this.handlers = handlers
      ? handlers.sort((obj, otherObj) => obj.priority - otherObj.priority)
      : [];
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.prepareRequest(req)).pipe(
      catchError((response: HttpErrorResponse) => {
        if (this.canIntercept(req)) {
          for (const handler of this.handlers) {
            const error = ErrorResponse.from(response);
            if (handler.matches(error)) {
              handler.handle(error);
            }
          }
        }
        return throwError(response);
      })
    );
  }

  private prepareRequest(request: HttpRequest<any>): HttpRequest<any> {
    if (request.headers.has(INTERCEPT_ERROR_RESPONSE_HEADER)) {
      return request.clone({
        headers: request.headers.delete(INTERCEPT_ERROR_RESPONSE_HEADER)
      });
    }
    return request;
  }

  private canIntercept(request: HttpRequest<any>): boolean {
    return (
      request.headers.has(INTERCEPT_ERROR_RESPONSE_HEADER) &&
      request.headers.get(INTERCEPT_ERROR_RESPONSE_HEADER) === InterceptorConfig.INTERCEPT.value
    );
  }
}
