import { SgvJson } from '@financial/arch';
import { CostCenter } from '../cost-centers/cost-center';

export class CostCenterSetting {

  constructor(
    public readonly costCenter: CostCenter = null,
    public readonly apportionment: number = 0
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      costCenter: this.costCenter.toJson()
    });
  }

  static fromJson(json: any): CostCenterSetting {
    return json
      ? SgvJson.from.simple(json, CostCenterSetting, {
        costCenter: CostCenter.fromJson(json.costCenter)
      })
      : null;
  }

}
