import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TopTenExpenses } from '@financial/domain';
import * as Chart from 'chart.js';
import { TopTenExpensesService } from './top-ten-expenses.service';

@Component({
  selector: 'app-top-ten-expenses',
  templateUrl: './top-ten-expenses.component.html',
  styleUrls: ['./top-ten-expenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopTenExpensesComponent implements OnInit {

  topTenExpenses: TopTenExpenses[] = [];
  selectedExpense: TopTenExpenses = null;
  expensesGraph: Chart = null;
  expensesChartLabels: string[] = [];
  expensesChartData: number[] = [];

  constructor(
    private topTenExpensesService: TopTenExpensesService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.topTenExpensesService.list().subscribe(
      result => {
        this.topTenExpenses = result;
        this.selectedExpense = result[0];
        result.forEach(dto => {
          this.expensesChartLabels.push(dto.person);
          this.expensesChartData.push(dto.value);
        });
      },
      error => console.log(error),
      () => this.loadExpensesGraph()
    );
  }

  loadExpensesGraph() {
    this.expensesGraph = new Chart('topTenExpenses', {
      type: 'doughnut',
      data: {
        labels: this.expensesChartLabels,
        datasets: [{
          data: this.expensesChartData,
          backgroundColor: [
            '#F44336',
            '#FF5722',
            '#FF9800',
            '#FFC107',
            '#FFEB3B',
            '#CDDC39',
            '#8BC34A',
            '#4CAF50',
            '#009688',
            '#2196F3'
          ]
        }],
      },
      options: {
        onHover: (event) => {
          var element: any = this.expensesGraph.getElementAtEvent(event);
          if (element.length > 0) {
            this.selectedExpense = this.topTenExpenses[element[0]._index];
            this.changeDetection.markForCheck();
          };
        },
        responsive: true,
        legend: {
          display: false
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 70
      }
    });
    this.changeDetection.markForCheck();
  }

}