import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import {
  Documents,
  NaturalPersonDocuments
} from '../core/documents';

export class EmployeeSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly cpf = ''
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): EmployeeSummary {
    return json ? SgvJson.from.simple(json, EmployeeSummary) : null;
  }
}

export class Employee implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public documents: NaturalPersonDocuments = new NaturalPersonDocuments(),
    public birth: Date = null,
    public registrationDate: Date = new Date(),
    public readonly isActive = true
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      documents: this.documents ? this.documents.toJson() : null
    });
  }

  static naturalPerson(): Employee {
    const entity = new Employee();
    entity.documents = new NaturalPersonDocuments();
    return entity;
  }

  static fromJson(json: any): Employee {
    return SgvJson.from.simple(json, Employee, {
      documents: json.documents ? Documents.fromJson(json.documents) : null,
      registrationDate: json.registrationDate ? new Date(json.registrationDate) : null,
      birth: json.birth ? new Date(json.birth) : null
    });
  }
}
