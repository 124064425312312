import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MonthPipe } from '@financial/common-components';
import { ApportionmentByCostCenterReport } from '@financial/domain';
import * as Chart from 'chart.js';
import { ApportionmentByCostCenterService } from './apportionment-by-cost-center.service';

@Component({
  selector: 'app-apportionment-by-cost-center',
  templateUrl: './apportionment-by-cost-center.component.html',
  styleUrls: ['./apportionment-by-cost-center.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApportionmentByCostCenterComponent implements OnInit {

  apportionments: ApportionmentByCostCenterReport = null;
  apportionmentsGraph: Chart = null;
  apportionmentsChartLabels: string[] = [];

  monthPipe: MonthPipe = new MonthPipe();

  chartColors: string[] = ['#f44336','#ff8c00','#fdb606','#8BC34A','#009688','#2196F3'];

  constructor(
    private apportionmentByCostCenterService: ApportionmentByCostCenterService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.apportionmentByCostCenterService.list().subscribe(
      result => {
        this.apportionments = result;
        result.months.forEach(month => {
          this.apportionmentsChartLabels.push(this.monthPipe.transform(month));
        });
      },
      error => console.log(error),
      () => this.loadApportionmentsGraph()
    );
  }

  loadApportionmentsGraph() {
    Chart.defaults.global.defaultFontFamily = "sans-serif";
    Chart.defaults.global.defaultFontSize = 14;

    this.apportionmentsGraph = new Chart('apportionment', {
      type: 'line',
      data: {
        labels: this.apportionmentsChartLabels
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true
          }
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                return value.toLocaleString("pt-BR",{style:"currency", currency:"BRL"});
              }
            }
          }]
        }
      }
    });

    for (let index = 0; index < this.apportionments.apportionments.length; index++) {
      const apportionment = this.apportionments.apportionments[index];
        this.apportionmentsGraph.data.datasets.push(
          {
              data: apportionment.amounts,
              backgroundColor: this.chartColors[index],
              borderWidth: 3,
              borderColor: this.chartColors[index],
              label: apportionment.costCenter,
              fill: false,
              lineTension: 0
          }
        );
    }
    this.apportionmentsGraph.update();

    this.changeDetection.markForCheck();
  }

}