import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { MenuGroup, MenuItem, SubMenu } from '../../../features/features-catalog';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { IconName } from '@fortawesome/fontawesome-svg-core';

interface EntryGroup {
  name: string;
  childs: Entry[];
  source: MenuGroup;
}

interface Entry {
  name: string;
  icon: IconName;
  path?: string;
  childs?: MenuGroup[];
  isSubmenu: boolean;
  source: MenuItem | SubMenu;
  details: string;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  @Output() submenuClick = new EventEmitter<SubMenu>();

  entries: EntryGroup[] = [];

  private _menu: MenuGroup[] = [];

  constructor(private changeDetector: ChangeDetectorRef) {}

  get menu() {
    return this._menu;
  }

  @Input() set menu(value: MenuGroup[]) {
    this._menu = value ? value : [];
    this.refreshMenu();
  }

  ngOnInit() {
  }

  refreshMenu() {
    this.entries = this.buildFromGroup(this.menu);
    this.changeDetector.markForCheck();
  }

  trackByFn(index: Number, entry: any) {
    return entry.feature || entry.name;
  }

  onSubmenuClick(entry: Entry) {
    if (entry.source instanceof SubMenu) {
      this.submenuClick.emit(entry.source);
    }
  }

  private buildFromGroup(value: MenuGroup[]): EntryGroup[] {
    return value
      .map(v => ({
        name: v.name,
        childs: this.buildFromItem(v.childs),
        source: v
      }))
      .filter(v => v.childs.length > 0);
  }

  private buildFromItem(value: (MenuItem | SubMenu)[]): Entry[] {
    return value
      .map(v => {
        if (v instanceof SubMenu) {
          return {
            icon: v.icon,
            name: v.name,
            details: v.details,
            source: v,
            isSubmenu: true,
            childs: v.childs
          };
        } else {
          return {
            icon: v.icon,
            name: v.name,
            details: v.details,
            source: v,
            isSubmenu: false,
            path: v.path
          };
        }
      });
  }

  private userCanAccess(item: MenuItem | SubMenu) {
    if (item instanceof SubMenu) {
      return item.childs.some(c2 => this.userCanAccessGroup(c2));
    } else if (item instanceof MenuItem) {
      return !item.feature;
    } else {
      return true;
    }
  }

  private userCanAccessGroup(item: MenuGroup) {
    return item.childs.some(c => this.userCanAccess(c));
  }

  ngOnDestroy() {}
}
