import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageData, ReadonlyRepository, SortOrder } from '@financial/arch';
import { RepositoryDataSource } from '../shared';
import { LoadQueue } from '../shared/load-helper';

interface EntityTableColumn {
  name: string;
  label: string;
  tooltip?: string;
  numeric?: boolean;
  format?: (value: any) => any;
  nested?: boolean;
  sortable?: boolean;
  hidden?: boolean;
  filter?: boolean;
  width?: number;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.scss']
})
export class EntityTableComponent<S> implements OnInit {
  @Input() title: string;

  @Input() columns: EntityTableColumn[] = [];

  @Input() sortBy: SortOrder;

  @Output() entitySelect = new EventEmitter<S>();

  @Output() loadFail = new EventEmitter<Error>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort) sort: MatSort;

  @Input() repository: ReadonlyRepository<S, any>;

  dataSource: RepositoryDataSource<S>

  loadQueue = new LoadQueue();

  constructor() { }

  get displayedColumns() {
    return this.columns.map(v => v.name)
  }

  get loading() {
    return !this.loadQueue.empty;
  }

  ngOnInit(): void {
    this.dataSource = new RepositoryDataSource(this.repository);
    this.dataSource.paginate(new PageData(this.paginator.pageIndex, this.paginator.pageSize));
  }

  onRowClick(entity: S) {
    this.entitySelect.emit(entity);
  }

  search(searchTerm: string): void {
    this.dataSource.search(searchTerm);
  }

  paginate(pagingEvent: PageEvent): void {
    this.dataSource.paginate(new PageData(pagingEvent.pageIndex, pagingEvent.pageSize));
  }

  invalidate() {
    this.dataSource.invalidate();
  }
}
