import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainEntity, Functionality, Repository } from '@financial/arch';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CrudMode, ListOnlyCrudMode } from './crud-mode';
import { EntityCrudDetailsContentDirective } from './entity-crud-details-content.directive';
import {
  EntityCrudDialogComponent,
  EntityCrudDialogParams,
  EntityCrudDialogResult,
  EntityCrudType
} from './entity-crud-dialog/entity-crud-dialog.component';
import { EntityCrudState } from './entity-crud-state';

export const ENTITY_TYPE_PARAM = 'entityType';
export const ENTITY_CRUD_STATE_PARAM = 'state';

export class EntityChangeEvent {
  readonly date = new Date();
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'entity-crud',
  templateUrl: './entity-crud.component.html',
  styleUrls: ['./entity-crud.component.scss']
})
export class EntityCrudComponent implements OnInit, OnDestroy {
  private _functionality: Functionality;

  @Input() repository: Repository<DomainEntity, DomainEntity>;

  @Input() showDefaultFab = true;

  @Input() entityTypes: EntityCrudType[];

  @Input() ignoreListRedirects = false;

  @Output() entityChange = new EventEmitter<EntityChangeEvent>();

  @ContentChild(EntityCrudDetailsContentDirective)
  detailsContent: EntityCrudDetailsContentDirective;

  @ViewChild('drawerRight') drawerRight: MatDrawer;

  @ViewChild('drawerLeft') drawerLeft: MatDrawer;

  currentDialog: MatDialogRef<EntityCrudDialogComponent, EntityCrudDialogResult> = null;

  private _state: CrudMode = new ListOnlyCrudMode();

  private dialogState$ = new BehaviorSubject(EntityCrudState.VIEW);

  private changed$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private changed = false;

  public isMobileScreen = false;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(
        untilDestroyed(this),
        map((state: BreakpointState) => state.matches),
        distinctUntilChanged()
      )
      .subscribe((match: boolean) => (this.isMobileScreen = match));
  }

  get functionality() {
    return this._functionality;
  }

  @Input()
  set functionality(value: Functionality) {
    this._functionality = value;
  }

  private get state() {
    return this._state;
  }

  private set state(state: CrudMode) {
    this._state = state;
    this.publishToDialog();
  }

  get userCanInsert() {
    return true;
  }

  get userCanUpdate() {
    return true;
  }

  get userCanDelete() {
    return false;
  }

  get userCanTempDelete() {
    return true;
  }

  ngOnInit() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(debounceTime(100))
      .subscribe(([rp, qp]) => {
        this.state = CrudMode.from(
          rp['id'],
          qp[ENTITY_TYPE_PARAM],
          EntityCrudState.get(qp[ENTITY_CRUD_STATE_PARAM])
        );
      });

    this.changed$.subscribe(changed => (this.changed = changed));
  }

  ngOnDestroy() {}

  hasUnsavedState() {
    return (
      (this.state.isEditMode || this.state.isNewMode) &&
      this.currentDialog.componentInstance.unsavedChanges
    );
  }

  openEntity(id: string, replaceUrl: boolean = false) {
    this.router.navigate([`../${id}`], {
      relativeTo: this.route,
      replaceUrl: replaceUrl
    });
  }

  newEntity(type?: string, replaceUrl: boolean = false) {
    if (this.userCanInsert) {
      const queryParams = {};
      queryParams[ENTITY_TYPE_PARAM] = type;
      this.router.navigate(['../new'], {
        relativeTo: this.route,
        queryParams: queryParams,
        replaceUrl: replaceUrl
      });
    }
  }

  editEntity(id: string, replaceUrl: boolean = false) {
    if (this.userCanUpdate) {
      const queryParams = {};
      queryParams[ENTITY_CRUD_STATE_PARAM] = EntityCrudState.EDIT.name.toLocaleLowerCase();
      this.router.navigate([`../${id}`], {
        relativeTo: this.route,
        queryParams: queryParams,
        replaceUrl: replaceUrl
      });
    }
  }

  private publishToDialog() {
    if (this.state.emptyUrlId && this.currentDialog) {
      this.currentDialog.close();
    } else if (!this.state.emptyUrlId && this.currentDialog) {
      this.dialogState$.next(this.state.state);
    } else if (!this.state.emptyUrlId && !this.currentDialog) {
      this.openDialog();
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }

  private openDialog() {
    if (!this.detailsContent) {
      console.error('Template de detalhes não definido');
      return false;
    }
    const type = this.state.entityType;
    const data: EntityCrudDialogParams = {
      functionality: this.functionality,
      repository: this.repository,
      content: this.detailsContent,
      entityTypes: this.entityTypes,
      changeCrudMode: (mode: CrudMode) => {
        if (mode.isNewMode) {
          this.newEntity(mode.entityType);
        } else if (mode.isEditMode) {
          if (this.state.isNewMode) {
            this.openEntity(mode.urlId, true);
            setTimeout(() => this.editEntity(mode.urlId), 100);
          } else {
            this.editEntity(mode.urlId, this.state.isNewMode);
          }
        } else {
          this.openEntity(
            mode.urlId,
            (mode.isViewMode && this.state.isNewMode) || this.state.isEditMode
          );
        }
      },
      type: type,
      mode$: this.dialogState$,
      changed$: this.changed$
    };

    if (this.state.isNewMode) {
      this.dialogState$.next(EntityCrudState.INSERT);
    } else {
      this.dialogState$.next(this.state.isEditMode ? EntityCrudState.EDIT : EntityCrudState.VIEW);
      data.id = this.state.urlId;
    }

    this.currentDialog = this.dialog.open(EntityCrudDialogComponent, {
      height: '600px',
      width: '1300px',
      panelClass: 'crud-dialog',
      disableClose: true,
      data: data,
      closeOnNavigation: false
    });
    this.currentDialog.afterClosed().subscribe(() => this.onDialogClose());
  }

  private onDialogClose() {
    if (this.changed) {
      this.entityChange.emit(new EntityChangeEvent());
    }
    this.currentDialog = null;
    this.state = new ListOnlyCrudMode();
  }
}
