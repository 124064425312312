import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { OnlineStatusService } from '@financial/arch';
import { SearchBoxComponent } from '@financial/common-components';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-layout-base',
  templateUrl: './layout-base.component.html',
  styleUrls: ['./layout-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutBaseComponent implements OnInit, OnDestroy {
  @Input() fillPrimary = false;

  @Input() pageTitle: string = null;

  @Input() showSearch = false;

  @Input() searchPlaceholder = 'Pesquisar';

  @Input() showMenu = false;

  @Input() showBack = false;

  @Input() homePath = '/';

  @Input() user: Observable<LayoutUser>;

  @Output() searchChange = new EventEmitter<string>();

  @Output() menuOpened$ = new EventEmitter<boolean>();

  @ViewChild(SearchBoxComponent) searchBox: SearchBoxComponent;

  @ViewChild('menuContainer') menuContainer: ElementRef;

  offline = false;

  private _menuOpened = false;

  private searchSubject = new BehaviorSubject('');

  @Input() onSideBackClick: () => void = () => this.closeMenu();

  constructor(
    private location: Location,
    public onlineStatusService: OnlineStatusService
    ) {
    this.searchSubject
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(search => this.searchChange.emit(search));
  }

  get menuOpened() {
    return this._menuOpened;
  }

  set menuOpened(value: boolean) {
    this.menuOpened$.emit((this._menuOpened = value));
  }

  get searchVisible() {
    return this.showSearch && this.searchBox && this.searchBox.searchVisible;
  }

  ngOnInit() {}

  closeMenu() {
    this.menuOpened = false;
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  onSearchChange(text: string) {
    this.searchSubject.next(text);
  }

  onSearchClear() {
    this.searchSubject.next('');
  }

  onGoBackClick() {
    this.location.back();
  }

  resetMenu() {
    this.menuContainer.nativeElement.scrollTo({ top: 0 });
  }

  ngOnDestroy(): void {}
}

export interface LayoutUser {
  name: string;
  identifier: string;
}
