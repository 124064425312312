import { Injectable } from '@angular/core';
import { ApiService, TemporalRepositoryImpl } from '@financial/arch';
import { ProvidersAccountingBalance } from '.';

@Injectable({ providedIn: 'root' })
export class ProvidersAccountingBalanceRepository extends TemporalRepositoryImpl<ProvidersAccountingBalance, ProvidersAccountingBalance> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/providersAccountingBalance'), ProvidersAccountingBalance, ProvidersAccountingBalance);
  }

}
