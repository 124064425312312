<mat-card>
  <mat-card-content>
    <mat-card-title>Receitas vs Despesas</mat-card-title>
    <div class="chart-container">
      <canvas id="incomesVsExpenses"></canvas>      
      <div class="chart-text" *ngIf="incomesVsExpenses && incomesVsExpenses.length > 0 && selectedIncomesVsExpenses">
        <p>{{ selectedIncomesVsExpenses.type | incomesVsExpenses }}</p>
        <h2>{{ selectedIncomesVsExpenses.percentage }}</h2>
        <small>{{ selectedIncomesVsExpenses.value | currency: 'BRL' }}</small>
      </div>
    </div>
  </mat-card-content>
</mat-card>