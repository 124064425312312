import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatDrawer } from '@angular/material/sidenav';
import { DateInterval, Filter } from '@financial/arch';
import { BooleanFilterDescription, EntityListPerspective, EntityListPerspectiveComponent, NumericFilterDescription, RefFilterDescription } from '@financial/common-components';
import { AccountsRepository, ApportionmentAnalysis, ChartOfAccountsRepository } from '@financial/domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ApportionmentChartOfAccountType } from './apportionment-chart-of-account-type';
import { ApportionmentAnalysisService } from './apportionment.service';


@Component({
  selector: 'app-apportionment-analysis',
  templateUrl: './apportionment.component.html',
  styleUrls: ['./apportionment.component.scss']
})
export class ApportionmentAnalysisComponent implements AfterViewInit, OnDestroy {

  static readonly PAGE_NAME: string = "Rateios";

  columns: Column[] = [
    new Column('#', true),
    new Column('Plano de Contas'),
    new Column('Data'),
    new Column('Valor'),
    new Column('Conta')
  ];
  entities: ApportionmentAnalysis[] = null;
  expandAll: boolean = false;
  dateInterval: DateInterval = DateInterval.ofMonth(new Date());
  @ViewChild(MatAccordion) private accordion: MatAccordion;

  @ViewChild('perspective') perspective: EntityListPerspectiveComponent;
  allowedFilters = [
    new BooleanFilterDescription('expense', 'Despesas'),
    new BooleanFilterDescription('income', 'Receitas'),
    new NumericFilterDescription('value', 'Valor'),
    new RefFilterDescription('account:id', 'Conta', this.accountRepository),
    new RefFilterDescription('chartOfAccount:id', 'Plano de Contas', this.chartOfAccountRepository, "classificationAndName")
  ];

  @ViewChild('drawerRight') drawerRight: MatDrawer;

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint() {
    this.drawerRight.close();
  }

  public isMobileScreen: Boolean = false;

  constructor(
    public service: ApportionmentAnalysisService,
    private accountRepository: AccountsRepository,
    private chartOfAccountRepository: ChartOfAccountsRepository,
    private breakpointObserver: BreakpointObserver
  ) {
    this.service.listByInterval(this.dateInterval)
      .subscribe(entities => this.entities = entities);

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(
        untilDestroyed(this),
        map((state: BreakpointState) => state.matches),
        distinctUntilChanged()
      )
      .subscribe((match: boolean) => (this.isMobileScreen = match));
  }

  ngOnDestroy() { }

  get pageName() {
    return ApportionmentAnalysisComponent.PAGE_NAME;
  }

  ngAfterViewInit() {
    this.perspective.valueChange.pipe(debounceTime(300))
      .subscribe((p: EntityListPerspective) => this.onFilterChange(p.filters));
  }

  async onIntervalChange(interval: DateInterval) {
    this.expandAll = false;
    this.dateInterval = interval;
    this.entities = await this.service.listByInterval(
      interval,
      this.perspective.value.filters
    ).toPromise();
  }

  async onFilterChange(filters: Filter[]) {
    this.expandAll = false;
    this.entities = await this.service.listByInterval(this.dateInterval, filters).toPromise();
  }

  onCheck(checked: boolean) {
    if (this.accordion) {
      if (checked) {
        this.accordion.openAll();
      } else {
        this.accordion.closeAll();
      }
    }
  }

  colorByType(type: string) {
    switch (type) {
      case ApportionmentChartOfAccountType.EXPENSE: {
        return 'red';
      }
      case ApportionmentChartOfAccountType.INCOME: {
        return 'blue';
      }
      default: {
        return 'inherit';
      }
    }
  }

}

class Column {
  constructor(
    readonly label: string,
    readonly hideOnPrint: boolean = false
  ) { }
}