import { animate, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CurrentUserService } from '@financial/domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAIN_MENU, SubMenu } from '../../features/features-catalog';
import { LayoutBaseComponent, LayoutUser } from '../base/layout-base.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout-financial.component.html',
  styleUrls: ['./layout-financial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('menuAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('150ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [animate('150ms ease-in', style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class LayoutfinancialComponent implements OnInit, OnDestroy {
  @Input() fillPrimary = false;

  @Input() pageTitle: string = null;

  @Input() showSearch = false;

  @Input() searchPlaceholder = 'Pesquisar';

  @Input() showMenu = true;

  @Input() showBack = false;

  @Output() searchChange = new EventEmitter<string>();

  @ViewChild(LayoutBaseComponent) layoutBase: LayoutBaseComponent;

  readonly mainMenu = MAIN_MENU.childs;

  submenu: SubMenu = null;

  homePath = '/';

  user: Observable<LayoutUser>;

  onSideBackClickFn = () => this.onSideBackClick();

  constructor(public currentUser: CurrentUserService) {
    this.user = zip(this.currentUser.$user, this.currentUser.$principalStr).pipe(
      untilDestroyed(this),
      map(([user, principal]) => {
        return {
          name: user.name,
          identifier: principal
        };
      })
    );
  }

  ngOnInit() {}

  onSideBackClick() {
    if (this.submenu) {
      this.submenu = null;
    } else {
      this.layoutBase.closeMenu();
    }
  }

  onSubmenuClick(submenu: SubMenu) {
    this.submenu = submenu;
    this.layoutBase.resetMenu();
  }

  ngOnDestroy(): void {}
}
