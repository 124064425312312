<mat-nav-list>
  <ng-content select="[before]"></ng-content>
  <ng-container *ngFor="let group of entries; let first = first; trackBy: trackByFn">
    <mat-divider *ngIf="!first"></mat-divider>
    <h3 mat-subheader>{{ group.name }}</h3>
    <ng-container *ngFor="let entry of group.childs; trackBy: trackByFn">
      <a *ngIf="!entry.isSubmenu" mat-list-item [routerLink]="entry.path">
        <mat-icon mat-list-icon [svgIcon]="entry.icon"></mat-icon>
        <h4 mat-line>{{ entry.name }}</h4>
        <p mat-line>{{ entry.details }}</p>
      </a>
      <a *ngIf="entry.isSubmenu" mat-list-item (click)="onSubmenuClick(entry)">
        <mat-icon mat-list-icon [svgIcon]="entry.icon"></mat-icon>
        <h4 mat-line>{{ entry.name }}</h4>
        <p mat-line>{{ entry.details }}</p>
        <mat-icon svgIcon="chevron-right"></mat-icon>
      </a>
    </ng-container>
  </ng-container>
  <ng-content select="[after]"></ng-content>
</mat-nav-list>
