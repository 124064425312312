import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Bank } from './bank';

@Injectable({ providedIn: 'root' })
export class BanksRepository extends RepositoryImpl<Bank, Bank> {
  constructor(api: ApiService) {
    super(api.root.path('banks'), Bank, Bank);
  }
}
