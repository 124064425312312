import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { AccountsPayment } from "@financial/domain";
import { Account } from "../../accounts";

export class AccountsBalance implements DomainEntity {

  constructor(
    public readonly id = SgvId.gen(),
    public readonly account: Account = null,
    public readonly payments: AccountsPayment[] = [],
    public readonly totalDebits: number = 0,
    public readonly totalCredits: number = 0,
    public readonly totalBalance: number = 0,
    public readonly previousBalance: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): AccountsBalance {
    return data ? SgvJson.from.simple(data, AccountsBalance) : null;
  }
}
