import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[asyncAction]',
  exportAs: 'asyncAction'
})
export class AsyncActionDirective {
  @Input() asyncAction: () => Promise<any>;

  private _running = false;

  constructor(private ref: ElementRef, private changeDetector: ChangeDetectorRef) {}

  get running() {
    return this._running;
  }

  set running(value: boolean) {
    this._running = value;
    this.updateState();
  }

  @HostListener('click')
  async onClick() {
    if (this.asyncAction) {
      try {
        this.running = true;
        await this.asyncAction();
      } finally {
        this.running = false;
      }
    }
  }

  private updateState() {
    this.ref.nativeElement.disabled = this._running;
    this.changeDetector.markForCheck();
  }
}
