import * as Moment from 'moment';

export const YEAR_MONTH_FORMAT = 'YYYY-MM';

export const LOCALDATE_FORMAT = 'YYYY-MM-DD'

export class DateUtils {
  static toString(date: Date, pattern?: string) {
    if (!pattern) pattern = LOCALDATE_FORMAT;
    return Moment(date).format(pattern);
  }

  public static compare(d1: Date, d2: Date): number {
    if (d1 > d2) {
      return 1;
    } else if (d1 < d2) {
      return -1;
    }
    return 0;
  }
}
