import { Injectable } from '@angular/core';
import { ApiPath, ApiService } from '@financial/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../users/user';
import { Principal } from './current-user.service';

@Injectable({ providedIn: 'root' })
export class CurrentInfoRepository {
  private apiPath: ApiPath;

  constructor(api: ApiService) {
    this.apiPath = api.root.path('current');
  }

  getPrincipal(): Observable<Principal> {
    return this.apiPath.getJson().pipe(map(j => j as Principal));
  }

  getUser(): Observable<User> {
    return this.apiPath
      .path('user')
      .getJson()
      .pipe(map(json => User.fromJson(json)));
  }
}
