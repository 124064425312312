import { Functionality } from '@financial/arch';
import {
  ACCOUNTING_CLOSINGS, ACCOUNTS, ACCOUNTS_BALANCE, APPORTIONMENT_ANALYSIS, BANKS, CASH_FLOW, CHART_OF_ACCOUNTS,
  CLIENTS, CLIENTS_ACCOUNTING_BALANCE, CLIENTS_BALANCE, COST_CENTERS, EMPLOYEES,
  PROVIDERS, PROVIDERS_ACCOUNTING_BALANCE, PROVIDERS_BALANCE
} from '@financial/domain';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAccusoft } from '@fortawesome/free-brands-svg-icons';
import {
  faAddressBook,
  faBook, faBookOpen, faCalculator, faCreditCard, faDolly, faIdCard, faLandmark, faRetweet, faShareAlt, faUniversity, faUser, faUserCircle, faUsers
} from '@fortawesome/free-solid-svg-icons';
import { AccountsBalanceComponent } from './analysis/accounts-balance/accounts-balance.component';
import { ApportionmentAnalysisComponent } from './analysis/apportionment/apportionment.component';
import { CashFlowComponent } from './analysis/cash-flow/cash-flow.component';
import { ClientsAccountingBalanceComponent } from './analysis/clients-accounting-balance/clients-accounting-balance.component';
import { ClientsBalanceComponent } from './analysis/clients-balance/clients-balance.component';
import { ProvidersAccountingBalanceComponent } from './analysis/providers-accounting-balance/providers-accounting-balance.component';
import { ProvidersBalanceComponent } from './analysis/providers-balance/providers-balance.component';
import { PathNames } from './features.routes';

export class MenuItem {
  constructor(
    readonly feature: Functionality = null,
    readonly name: string,
    readonly iconRef: IconDefinition,
    readonly path: string,
    readonly details: string = null,
    readonly title: string = name
  ) { }
  readonly icon = this.iconRef.iconName;

  get icons(): IconDefinition[] {
    return [this.iconRef];
  }
}
export class SubMenu {
  constructor(
    readonly name: string,
    readonly iconRef: IconDefinition,
    readonly details: string = null,
    readonly title: string = name,
    readonly childs: MenuGroup[]
  ) { }

  readonly icon = this.iconRef.iconName;

  get icons(): IconDefinition[] {
    return [this.iconRef, ...this.childs.map(c => c.icons).reduce((v, acc) => [...v, ...acc], [])];
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const group of this.childs) {
      const result = group.getItem(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }
}
export class MenuGroup {
  constructor(readonly name: string, readonly childs: (MenuItem | SubMenu)[]) { }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const child of this.childs) {
      if (child instanceof SubMenu) {
        const result = child.getItem(matcher);
        if (result) {
          return result;
        }
      } else if (child instanceof MenuItem) {
        if (matcher(child)) {
          return child;
        }
      }
    }
    return null;
  }

  getSubMenu(matcher: (item: SubMenu) => boolean): SubMenu {
    for (const child of this.childs) {
      if (child instanceof SubMenu) {
        if (matcher(child)) {
          return child;
        }
      }
    }
    return null;
  }

  get icons(): IconDefinition[] {
    return this.childs
      .map(c => c.icons)
      .reduce((v, acc) => [...(Array.isArray(v) ? v : [v]), ...acc], []);
  }
}
export class Menu {
  constructor(readonly childs: MenuGroup[]) { }

  getByFunctionality(feature: Functionality): MenuItem {
    return this.getItem(i => i.feature === feature);
  }

  getByPath(path: string): MenuItem {
    return this.getItem(i => i.path === path);
  }

  getItem(matcher: (item: MenuItem) => boolean): MenuItem {
    for (const group of this.childs) {
      const result = group.getItem(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }

  getSubMenu(matcher: (item: SubMenu) => boolean): SubMenu {
    for (const group of this.childs) {
      const result = group.getSubMenu(matcher);
      if (result) {
        return result;
      }
    }
    return null;
  }

  get icons(): IconDefinition[] {
    return this.childs.map(c => c.icons).reduce((v, acc) => [...v, ...acc], []);
  }
}

export const MAIN_MENU = new Menu([

  new MenuGroup('Contabilidade', [
    new MenuItem(
      ACCOUNTING_CLOSINGS,
      'Fechamento Contábil',
      faCalculator,
      `/${PathNames.accounting}/accountingclosings`,
      'Gerencie os fechamentos contábeis'
    ),
  ]),


  new MenuGroup('Análises', [
    new MenuItem(
      APPORTIONMENT_ANALYSIS,
      ApportionmentAnalysisComponent.PAGE_NAME,
      faShareAlt,
      `/${PathNames.analysis}/apportionment`,
      'Análises das composições dos rateios'
    ),
    new MenuItem(
      CLIENTS_BALANCE,
      ClientsBalanceComponent.PAGE_NAME,
      faIdCard,
      `/${PathNames.analysis}/clientsbalance`,
      'Situação financeira de clientes'
    ),
    new MenuItem(
      PROVIDERS_BALANCE,
      ProvidersBalanceComponent.PAGE_NAME,
      faAddressBook,
      `/${PathNames.analysis}/providersbalance`,
      'Situação financeira de fornecedores'
    ),
    new MenuItem(
      CLIENTS_ACCOUNTING_BALANCE,
      ClientsAccountingBalanceComponent.PAGE_NAME,
      faUser,
      `/${PathNames.analysis}/clientsaccountingbalance`,
      'Transações realizadas por clientes'
    ),
    new MenuItem(
      PROVIDERS_ACCOUNTING_BALANCE,
      ProvidersAccountingBalanceComponent.PAGE_NAME,
      faUserCircle,
      `/${PathNames.analysis}/providersaccountingbalance`,
      'Transações realizadas por fornecedores'
    ),
    new MenuItem(
      ACCOUNTS_BALANCE,
      AccountsBalanceComponent.PAGE_NAME,
      faUniversity,
      `/${PathNames.analysis}/accountsbalance`,
      'Movimentações das contas'
    ),
    new MenuItem(
      CASH_FLOW,
      CashFlowComponent.PAGE_NAME,
      faRetweet,
      `/${PathNames.analysis}/cashflow`,
      'Movimentações financeiras da empresa'
    )
  ]),


  new MenuGroup('Financeiro', [
    new MenuItem(
      BANKS,
      'Bancos',
      faLandmark,
      `/${PathNames.financial}/banks`,
      'Gerencie os cadastros dos seus bancos'
    ),
    new MenuItem(
      ACCOUNTS,
      'Contas',
      faAccusoft,
      `/${PathNames.financial}/accounts`,
      'Seu caixa e contas bancárias'
    ),
    new MenuItem(
      CHART_OF_ACCOUNTS,
      'Plano de Contas',
      faBookOpen,
      `/${PathNames.financial}/chartofaccounts`,
      'Gerencie os cadastros de plano de contas'
    ),
    new MenuItem(
      COST_CENTERS,
      'Centros de Custos',
      faCreditCard,
      `/${PathNames.financial}/costcenters`,
      'Gerencie os cadastros dos seus centros de custos'
    )
  ]),


  new MenuGroup('Pessoas', [
    new MenuItem(
      CLIENTS,
      'Clientes',
      faBook,
      `/${PathNames.persons}/clients`,
      'Gerencie os cadastros dos seus clientes'
    ),
    new MenuItem(
      EMPLOYEES,
      'Colaboradores',
      faUsers,
      `/${PathNames.persons}/employees`,
      'Gerencie os cadastros dos seus colaboradores'
    ),
    new MenuItem(
      PROVIDERS,
      'Fornecedores',
      faDolly,
      `/${PathNames.persons}/providers`,
      'Gerencie os cadastros dos seus fornecedores'
    )
  ])

]);
