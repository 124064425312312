import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { Expense, Payment, Provider } from "@financial/domain";


export class ProvidersBalance implements DomainEntity {

  constructor(
    public readonly id = SgvId.gen(),
    public readonly provider : Provider = new Provider(),
    public readonly expenses: Expense[] = [],
    public readonly payments: Payment[] = [],
    public readonly pendingExpenses: number = 0,
    public readonly totalBalance: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): ProvidersBalance {
    return data ? SgvJson.from.simple(data, ProvidersBalance) : null;
  }
}
