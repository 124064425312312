import { SgvJson, SgvId, JsonEntity } from '@financial/arch';
import { ChartOfAccountSummary } from '../../chart-of-account/chart-of-account';
import { CostCenter } from '../../cost-centers/cost-center';
import { Payment } from '../../payables/payments/payment';

export enum ConsolidatedPaymentStatus {
  APPROPRIATE,
  WITH_NO_CHART_OF_ACCOUNT,
  DUPLICATED_WITH_NO_COST_CENTER,
  DUPLICATED_WITH_MISSING_COST_CENTERS,
  WITH_UNMATCHED_APPORTIONMENT
}

export class ConsolidatedPayment implements JsonEntity {

  constructor(
    public payment: Payment = null,
    public chartOfAccount: ChartOfAccountSummary = new ChartOfAccountSummary(),
    public value: number = 0,
    public status: ConsolidatedPaymentStatus = null,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      payment: this.payment.toJson(),
      chartOfAccount: this.chartOfAccount.toJson(),
    });
  }

  static fromJson(data: any): ConsolidatedPayment {
    return data ? SgvJson.from.simple(data, ConsolidatedPayment, {
      payment: Payment.fromJson(data.payment),
      chartOfAccount: ChartOfAccountSummary.fromJson(data.chartOfAccount),
    }) : null;
  }

  isValid(): boolean {
    return this.status === ConsolidatedPaymentStatus.APPROPRIATE;
  }
}
