import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ApportionmentTypePipe } from './apportionment-type.pipe';

const PIPES = [
    ApportionmentTypePipe
];

@NgModule({
    imports: [CommonModule],
    providers: [CurrencyPipe],
    declarations: PIPES,
    exports: PIPES
})
export class PipesModule { }