import { SgvJson } from "@financial/arch";
import { ApportionmentAnalysisConsolidatedPayment } from "./apportionment-analysis-consolidated-payments";

export class ApportionmentAnalysis {

    constructor(
        public readonly costCenterName: string = '',
        public readonly totalValue: number = 0,
        public readonly consolidatedPayments: ApportionmentAnalysisConsolidatedPayment[] = []
    ) { }

    toJson() {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any) {
        return json ? SgvJson.from.simple(json, ApportionmentAnalysis, {
            consolidatedPayments: SgvJson.from.array(json.consolidatedPayments, ApportionmentAnalysisConsolidatedPayment.fromJson)
        }) : null;
    }

}