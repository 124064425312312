import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MonthPipe } from '@financial/common-components';
import { IncomesAndExpenses } from '@financial/domain';
import * as Chart from 'chart.js';
import { IncomesAndExpensesService } from './incomes-and-expenses.service';

@Component({
  selector: 'app-incomes-and-expenses',
  templateUrl: './incomes-and-expenses.component.html',
  styleUrls: ['./incomes-and-expenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncomesAndExpensesComponent implements OnInit {

  incomesAndExpenses: IncomesAndExpenses[] = [];
  incomesAndExpensesGraph: Chart = null;
  incomesAndExpensesChartLabels: string[] = [];
  incomesChartData: number[] = [];
  expensesChartData: number[] = [];

  monthPipe: MonthPipe = new MonthPipe();

  constructor(
    private incomesAndExpensesService: IncomesAndExpensesService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.incomesAndExpensesService.list().subscribe(
      result => {
        this.incomesAndExpenses = result;
        result.forEach(dto => {
          this.incomesAndExpensesChartLabels.push(this.monthPipe.transform(dto.month));
          this.incomesChartData.push(dto.incomes);
          this.expensesChartData.push(dto.expenses);
        });
      },
      error => console.log(error),
      () => this.loadIncomesAndExpensesGraph()
    );
  }

  loadIncomesAndExpensesGraph() {
    Chart.defaults.global.defaultFontFamily = "sans-serif";
    Chart.defaults.global.defaultFontSize = 14;

    this.incomesAndExpensesGraph = new Chart('incomesAndExpenses', {
      type: 'bar',
      data: {
        labels: this.incomesAndExpensesChartLabels,
        datasets: [
          {
            data: this.expensesChartData,
            backgroundColor: '#f44336',
            barPercentage: 0.5,
            label: 'Pagar',
          },
          {
            data: this.incomesChartData,
            backgroundColor: '#4db6ac',
            barPercentage: 0.5,
            label: 'Receber',
          }
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true
          }
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
              }
            }
          }]
        }
      }
    });
    this.changeDetection.markForCheck();
  }

}