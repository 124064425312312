import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { CostCenterSetting } from './cost-center-setting';
import { SettingEvent, SettingEventSummary } from './setting-event';

export class ChartOfAccountSummary implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public classification: string = '',
    public code: string = '',
    public path: ChartOfAccountPath[] = [],
    public costCenterSettings: CostCenterSetting[] = [],
    public settings: SettingEventSummary[] = []
  ) { }

  get label(): string {
    if (this.costCenterSettings) {
      return `${this.classification} - ${this.name} - ${this.costCenterSettings.map(
        (costCenterSettings) =>
          costCenterSettings.costCenter.name && costCenterSettings.apportionment
      )}`;
    }
    return `${this.classification} - ${this.name}`;
  }

  get classificationAndName(): string {
    return `${this.classification} - ${this.name}`;
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): ChartOfAccountSummary {
    return SgvJson.from.simple(data, ChartOfAccountSummary, {
      path: data.path.map((p) => ChartOfAccountPath.fromJson(p))
    });
  }
}

export class ChartOfAccountPath {
  constructor(
    readonly id: string = SgvId.gen(), 
    public name: string = ''
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): ChartOfAccountPath {
    return SgvJson.from.simple(data, ChartOfAccountPath);
  }
}

export class ChartOfAccount implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public classification: string = '',
    public code: string = '',
    public root: ChartOfAccountSummary = null,
    public costCenterSettings: CostCenterSetting[] = [],
    public settings: SettingEvent[] = []
  ) { }

  get label(): string {
    if (this.costCenterSettings) {
      return `${this.classification} - ${this.name} - ${this.costCenterSettings.map(
        (costCenterSettings) =>
          costCenterSettings.costCenter.name && costCenterSettings.apportionment
      )}`;
    }
    return `${this.classification} - ${this.name}`;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      costCenterSettings: SgvJson.to.array(this.costCenterSettings),
      settings: SgvJson.to.array(this.settings)
    });
  }

  static fromJson(data: any): ChartOfAccount {
    return SgvJson.from.simple(data, ChartOfAccount, {
      root: data.root ? ChartOfAccountSummary.fromJson(data.root) : null,
      costCenterSettings: SgvJson.from.array(data.costCenterSettings, CostCenterSetting.fromJson),
      settings: SgvJson.from.array(data.settings, SettingEvent.fromJson)
    });
  }
}
