<app-layout pageTitle="Início" [fillPrimary]="true">
  <section class="dashboard">
    <app-incomes-vs-expenses></app-incomes-vs-expenses>
    <app-incomes-and-expenses></app-incomes-and-expenses>
    <app-cash-flow-by-period></app-cash-flow-by-period>
    <app-apportionment-by-cost-center></app-apportionment-by-cost-center>
    <app-top-ten-expenses></app-top-ten-expenses>
    <app-top-ten-incomes></app-top-ten-incomes>
  </section>
</app-layout>
