import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IncomesVsExpenses } from '@financial/domain';
import * as Chart from 'chart.js';
import { IncomesVsExpensesService } from './incomes-vs-expenses.service';

@Component({
  selector: 'app-incomes-vs-expenses',
  templateUrl: './incomes-vs-expenses.component.html',
  styleUrls: ['./incomes-vs-expenses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncomesVsExpensesComponent implements OnInit {

  incomesVsExpenses: IncomesVsExpenses[] = [];
  selectedIncomesVsExpenses: IncomesVsExpenses = null;
  incomesVsExpensesGraph: Chart = null;
  incomesVsExpensesChartLabels: string[] = [];
  incomesVsExpensesChartData: number[] = [];

  constructor(
    private incomesVsExpensesService: IncomesVsExpensesService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.incomesVsExpensesService.list().subscribe(
      result => {
        this.incomesVsExpenses = result;
        this.selectedIncomesVsExpenses = result[0];
        result.forEach(dto => {
          this.incomesVsExpensesChartLabels.push(dto.type);
          this.incomesVsExpensesChartData.push(dto.value);
        });
      },
      error => console.log(error),
      () => this.loadIncomesVsExpensesGraph()
    );
  }

  loadIncomesVsExpensesGraph() {
    this.incomesVsExpensesGraph = new Chart('incomesVsExpenses', {
      type: 'doughnut',
      data: {
        labels: this.incomesVsExpensesChartLabels,
        datasets: [{
          data: this.incomesVsExpensesChartData,
          backgroundColor: [
            '#4db6ac',
            '#f44336'
          ]
        }],
      },
      options: {
        onHover: (event) => {
          var element: any = this.incomesVsExpensesGraph.getElementAtEvent(event);
          if (element.length > 0) {
            this.selectedIncomesVsExpenses = this.incomesVsExpenses[element[0]._index];
            this.changeDetection.markForCheck();
          };
        },
        responsive: true,
        legend: {
          display: false
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 70
      }
    });
    this.changeDetection.markForCheck();
  }

}