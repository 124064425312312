import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { DateInterval, Filter, Functionality } from '@financial/arch';
import { EntityListPerspective, EntityListPerspectiveComponent, FilterDescription, FilterType } from '@financial/common-components';
import { CashFlowWrapper, CASH_FLOW } from '@financial/domain';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { CashFlowService } from './cash-flow.service';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashFlowComponent implements AfterViewInit, OnDestroy {

  static readonly PAGE_NAME: string = "Fluxo de Caixa";

  @ViewChild('perspective') perspective: EntityListPerspectiveComponent;

  allowedFilters = [
    new FilterDescription('transfer', 'Transferências', FilterType.BOOLEAN),
    new FilterDescription('cancelled', 'Cancelados', FilterType.BOOLEAN)
  ];

  functionality: Functionality = CASH_FLOW;

  displayedColumns: string[] = ['date', 'name', 'type', 'description', 'chartOfAccount', 'account', 'person', 'value'];

  footerColumns: string[] = ['totalCredit', 'totalDebit'];

  dataSource: CashFlowWrapper = null;

  dateInterval: DateInterval = DateInterval.ofMonth(new Date());

  filters: Filter[] = [];

  public isMobileScreen: Boolean = false;

  constructor(
    private service: CashFlowService,
    private changeDetector: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
    this.onIntervalChange(this.dateInterval);

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(
        untilDestroyed(this),
        map((state: BreakpointState) => state.matches),
        distinctUntilChanged()
      )
      .subscribe((match: boolean) => (this.isMobileScreen = match));
  }

  ngOnDestroy() { }

  get pageName() {
    return CashFlowComponent.PAGE_NAME;
  }

  ngAfterViewInit() {
    this.perspective.valueChange.pipe(debounceTime(300)).subscribe(
      (p: EntityListPerspective) => this.onFilterChange(p.filters)
    );
  }

  async onIntervalChange(interval: DateInterval) {
    this.dateInterval = interval;
    this.dataSource = await this.service.listByInterval(interval, this.filters).toPromise();
    this.changeDetector.markForCheck();
  }

  async onFilterChange(filters: Filter[]) {
    this.filters = filters;
    this.dataSource = await this.service.listByInterval(this.dateInterval, filters).toPromise();
    this.changeDetector.markForCheck();
  }

}
