<section class="picker">
  <app-date-range-picker
    class="date-picker"
    [(interval)]="interval"
    [withTime]="withTime"
  ></app-date-range-picker>
</section>
<section *ngIf="!(onlineService.$online | async)" class="message">
  <h2>Você está offline</h2>
  <small>Não é possível carregar as informações</small>
</section>
<ng-container *ngIf="onlineService.$online | async">
  <section *ngIf="loading && filteredData.length === 0" class="message">
    <h2>Carregando informações</h2>
  </section>
  <section *ngIf="!loading && filteredData.length === 0 && !(loadFail | async)" class="message">
    <h2>Nenhum registro encontrado</h2>
    <small *ngIf="isFiltered">Nenhum registro corresponde aos critérios de busca</small>
  </section>
  <section *ngIf="!loading && filteredData.length === 0 && (loadFail | async)" class="message">
    <h2>Falha ao buscar os registros</h2>
    <small>
      <a href="javascript:void(0)" (click)="invalidate()">Tente novamente</a> em alguns instantes
    </small>
  </section>
  <section
    *ngIf="filteredData.length > 0"
    #cards
    class="cards"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="300"
    [scrollWindow]="false"
    (scrolled)="onScrollDown()"
  >
    <ng-container *ngFor="let item of filteredData; trackBy: trackByFn">
      <ng-container
        *ngTemplateOutlet="cardTemplate?.templateRef; context: { $implicit: item }"
      ></ng-container>
    </ng-container>
  </section>
</ng-container>
