import { Injectable } from '@angular/core';
import { ApiService, TemporalRepositoryImpl } from '@financial/arch';
import { AccountsBalance } from '.';

@Injectable({ providedIn: 'root' })
export class AccountsBalanceRepository extends TemporalRepositoryImpl<AccountsBalance, AccountsBalance> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/accountsBalance'), AccountsBalance, AccountsBalance);
  }

}
