import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DateInterval } from '@financial/arch';
import { DateRange } from './date-range';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent implements OnInit {
  @Input() intervalOptions = DateRange.values();

  @Input() interval = DateInterval.ofToday();

  @Output() intervalChange = new EventEmitter<DateInterval>();

  @Input() withTime = false;

  ngOnInit() {}

  goNext() {
    this.interval = this.interval.next();
    this.intervalChange.emit(this.interval);
  }

  goPrevious() {
    this.interval = this.interval.previous();
    this.intervalChange.emit(this.interval);
  }

  selectOption(o: DateRange) {
    this.interval = o.interval;
    this.intervalChange.emit(this.interval);
  }

  trackOptionBy(o: DateRange) {
    return o.name;
  }
}
