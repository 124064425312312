import { Injectable } from "@angular/core";
import { ApiPath, ApiService, DateInterval, Filter, UrlSearchParamsBuilder } from "@financial/arch";
import { CashFlowWrapper } from "@financial/domain";
import * as moment from "moment";
import { Observable } from "rxjs";
import { retry } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class CashFlowService {

    private apiPath: ApiPath;

    constructor(
        api: ApiService,
    ) {
        this.apiPath = api.root.path('reports').path('cashFlow');
    }

    listByInterval(interval: DateInterval, filters: Filter[]): Observable<CashFlowWrapper> {
        const search = new UrlSearchParamsBuilder()
            .filters(this.applyFilters(filters))
            .build();
        return this.apiPath.path(this.parseInterval(interval))
            .getJson<CashFlowWrapper>({params: search}).pipe(retry(3));
    }

    private parseDate(date: Date): string {
        const m = moment.utc(date);
        return `${m.format('YYYY-MM-DD')}T${m.format('HH:mm:ss')}Z`;
    }

    private parseInterval(interval: DateInterval): string {
        return `${this.parseDate(interval.begin)}--${this.parseDate(interval.end)}`;
    }

    private applyFilters(filters: Filter[]): Filter[] {
        return [...filters, ...(filters || [])];
    }

}
