import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@financial/default-shared';
import { LayoutBaseModule } from './base/layout-base.module';
import { LayoutfinancialModule } from './financial/layout-financial.module';

const MODULES = [LayoutBaseModule, LayoutfinancialModule];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, ...MODULES],
  declarations: [],
  exports: MODULES
})
export class LayoutModule {}
