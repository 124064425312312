import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'incomesVsExpenses'
})
export class IncomesVsExpensesPipe implements PipeTransform {

  transform(type: string): string {
    if (type) type = type.toLowerCase();

    switch (type) {
      case 'expense':
        return 'Despesa';
      case 'income':
        return 'Receita';
    }

  }

}