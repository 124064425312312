import { ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';


export const PathNames =
{
  persons: 'persons',
  financial: 'financial',
  accounting: 'accounting',
  analysis: 'analysis'
};


export const FeaturesRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild([
  {
    path: PathNames.persons,
    loadChildren: () => import(`./persons/persons.module`).then(m => m.PersonsModule)
  },
  {
    path: PathNames.financial,
    loadChildren: () => import(`./financial/financial.module`).then(m => m.FinancialModule)
  },
  {
    path: PathNames.accounting,
    loadChildren: () => import(`./accounting/accounting.module`).then(m => m.AccountingModule)
  },
  {
    path: PathNames.analysis,
    loadChildren: () => import(`./analysis/analysis.module`).then(m => m.AnalysisModule)
  }
]);
