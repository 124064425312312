import { SgvJson } from "@financial/arch";
import { ApportionmentAnalysisChartOfAccount } from "./apportionment-analysis-chart-of-accounts";
import { ApportionmentAnalysisPayment } from "./apportionment-analysis-value";

export class ApportionmentAnalysisConsolidatedPayment {

    constructor(
        public readonly value: number = 0,
        public readonly payment: ApportionmentAnalysisPayment = null,
        public readonly chartOfAccount: ApportionmentAnalysisChartOfAccount = null
    ) { }

    toJson() {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any) {
        return json ? SgvJson.from.simple(json, ApportionmentAnalysisConsolidatedPayment, {
            payment: ApportionmentAnalysisPayment.fromJson(json.payment),
            chartOfAccount: ApportionmentAnalysisChartOfAccount.fromJson(json.chartOfAccount)
        }) : null;
    }

}