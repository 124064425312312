import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Filter, PageData, ReadonlyRepository, SortOrder } from '@financial/arch';
import { RequiredHandler } from '@financial/common-utils';
import { BehaviorSubject, combineLatest, interval, Subscription } from 'rxjs';
import { debounce, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { LoadQueue } from '../shared/load-helper';

const DEFAULT_DEBOUNCE_TIME = 500;
const DEFAULT_MIN_QUERY_LENGTH = 3;

interface QueryData {
  query: string;
  filters: Filter[];
  sorts: SortOrder[];
  showAll: boolean;
  page: PageData;
}
@Component({
  selector: 'app-data-selector',
  templateUrl: './data-selector.component.html',
  styleUrls: ['./data-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSelectorComponent<T> extends RequiredHandler
  implements OnInit, AfterViewInit, ControlValueAccessor {
  @ContentChild('itemView') itemView: TemplateRef<any>;
  @ViewChild('input', { static: false }) input: ElementRef;
  @ViewChild('autocomplete') autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() repository: ReadonlyRepository<any, T>;
  @Input() minQueryLength = DEFAULT_MIN_QUERY_LENGTH;
  @Input() debounceTime = DEFAULT_DEBOUNCE_TIME;
  @Input() noLimitResults = false;
  @Input() lazyLoading = true;
  @Input() trackBy = 'id';
  @Output() valueChange = new EventEmitter<T>();
  @Output() loadFail = new EventEmitter<Error>();
  private _maxResults = 10;
  private _display = 'name';
  private onTouched: () => void = null;
  private onChangeSubscription: Subscription = null;

  innerValue: T;

  /* Internal */
  private querySubject = new BehaviorSubject<string>('');
  private filtersSubject = new BehaviorSubject<Filter[]>([]);
  private sortSubject = new BehaviorSubject<SortOrder[]>([]);
  private showAllSubject = new BehaviorSubject<boolean>(false);
  private pageSubject = new BehaviorSubject<PageData>(new PageData(0, this.maxResults));

  private readonly emptyQueryData = {
    query: '',
    filters: [],
    sorts: [],
    showAll: false,
    page: new PageData(0, this.maxResults)
  };
  private loadSubject = new BehaviorSubject<QueryData>(this.emptyQueryData);

  private loadQueue = new LoadQueue();

  filteredData: T[] = [];

  @Input() displayWith: (s: T) => string = s =>
    s ? `${this.display in s ? (s as any)[this.display] : s}` : '';

  @Input() trackByFn: (index: number, entity: any) => any = (index: number, entity: any) =>
    this.trackBy in entity ? entity[this.trackBy] : entity;

  constructor(@Optional() @Self() ngControl: NgControl, private changeDetector: ChangeDetectorRef) {
    super(ngControl);
    super.setControlValueAccessor(this);
    combineLatest([
      this.querySubject.pipe(
        map(q => q || ''),
        map(q => q.trim()),
        distinctUntilChanged(),
        debounce(() => interval(this.debounceTime))
      ),
      this.filtersSubject.pipe(distinctUntilChanged()),
      this.sortSubject.pipe(distinctUntilChanged()),
      this.showAllSubject.pipe(debounceTime(100), distinctUntilChanged()),
      this.pageSubject.pipe(distinctUntilChanged())
    ])
      .pipe(
        map(([query, filters, sorts, showAll, page]) => {
          return {
            query: query,
            filters: filters,
            sorts: sorts,
            showAll: showAll,
            page: page
          };
        }),
        distinctUntilChanged()
      )
      .subscribe(queryData => this.loadSubject.next(queryData));
  }

  ngOnInit() {
    this.loadSubject
      .pipe(
        filter(data => Boolean(data) && Boolean(this.repository)),
        debounceTime(300),
        distinctUntilChanged((obj, anotherObj) => this.isQueryDataEquals(obj, anotherObj))
      )
      .subscribe(data => {
        this.refresh(data);
      });
  }

  ngAfterViewInit(){
    this.propagateInputValue(); 
  }

  get display(): string {
    return this._display;
  }

  @Input()
  set display(value: string) {
    this._display = value;
    this.propagateInputValue();
  }

  get value() {
    return this.innerValue;
  }

  @Input()
  set value(v: T) {
    if (this.innerValue !== v) {
      this.innerValue = v;
      this.propagateValueChange();
    }
    this.propagateInputValue();
  }

  @Input()
  set filters(filters: Filter[]) {
    this.filtersSubject.next(filters);
  }

  @Input()
  set sorts(sorts: SortOrder[]) {
    this.sortSubject.next(sorts);
  }

  get maxResults(): number {
    return this._maxResults;
  }

  @Input()
  set maxResults(maxResults: number) {
    this._maxResults = maxResults;
    this.pageSubject.next(new PageData(this.pageSubject.value.first, this.maxResults));
  }

  onKeyup(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = (input.value || '').trim();
    this.querySubject.next(value);
  }

  onFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 0) {
      input.setSelectionRange(0, input.value.length);
    }
  }

  onBlur(event: FocusEvent) {
    if (this.onTouched) {
      this.onTouched();
    }
    const input = event.target as HTMLInputElement;
    const strValue = this.displayWith(this.value);
    if (input.value !== strValue) {
      this.value = null;
      this.querySubject.next(null);
    }
    this.showAllSubject.next(false);
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    this.value = event.option.value;
    this.clearEntityList();
  }

  clearEntityList() {
    this.querySubject.next(null);
  }

  propagateValueChange() {
    this.valueChange.emit(this.value);
  }

  writeValue(obj: T): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    this.onChangeSubscription = this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  togglePanel(evt: MouseEvent) {
    evt.stopPropagation();
    if (!this.autocomplete.isOpen) {
      this.showAllSubject.next(true);
    }

    setTimeout(() => {
      if (!this.autocomplete.isOpen) {
        this.trigger.openPanel();
      } else {
        this.trigger.closePanel();
      }
    }, 10);
  }

  onScrollDown(event: any) {
    if (this.lazyLoading) {
      this.loadNextPage();
    }
  }

  private async loadNextPage() {
    const queryData = this.loadSubject.value;
    queryData.page = queryData.page.next;
    this.runNetworkOp(
      async () => (this.filteredData = [...this.filteredData, ...(await this.fetchPage(queryData))])
    );
  }

  private propagateInputValue() {
    const value = this.displayWith(this.innerValue);
    if (this.input != null && this.input.nativeElement.value !== value) {
      this.input.nativeElement.value = value;
    }
  }

  private async refresh(queryData: QueryData = this.loadSubject.value) {
    this.runNetworkOp(async () => {
      if (this.autocomplete && this.autocomplete.panel) {
        this.autocomplete.panel.nativeElement.scrollTo(0, 0);
      }
      if (queryData.showAll || (queryData.query && queryData.query.length >= this.minQueryLength)) {
        if (this.maxResults > 0 && !this.noLimitResults) {
          this.filteredData = await this.fetchPage(queryData);
        } else {
          this.filteredData = await this.fetchAll(queryData);
        }
      } else {
        this.filteredData = [];
      }
    });
  }

  private async fetchPage(queryData: QueryData = this.loadSubject.value): Promise<any[]> {
    return await this.repository
      .page(queryData.page, queryData.query, queryData.filters, queryData.sorts)
      .toPromise();
  }

  private async fetchAll(queryData: QueryData = this.loadSubject.value): Promise<any[]> {
    return await this.repository
      .list(queryData.query, queryData.filters, queryData.sorts)
      .toPromise();
  }

  private async runNetworkOp<T2>(action: () => Promise<T2>): Promise<T2> {
    const load = this.loadQueue.enqueue();
    try {
      this.changeDetector.detectChanges();
      const result = await action();
      this.loadFail.emit(null);
      return result;
    } catch (e) {
      this.loadFail.emit(e);
    } finally {
      this.loadQueue.markFinished(load);
      this.changeDetector.markForCheck();
    }
  }

  private isQueryDataEquals(obj: QueryData, anotherObj: QueryData): boolean {
    return (
      obj &&
      anotherObj &&
      obj.query === anotherObj.query &&
      obj.showAll === anotherObj.showAll &&
      (obj.filters === anotherObj.filters || this.isArrayEqual(obj.filters, anotherObj.filters)) &&
      (obj.sorts === anotherObj.sorts || this.isArrayEqual(obj.sorts, anotherObj.sorts)) &&
      (obj.page === anotherObj.page || obj.page.isEqual(anotherObj.page))
    );
  }

  private isArrayEqual(v1: any[], v2: any[]) {
    if (v1.length !== v2.length) {
      return false;
    }
    for (let i = 0; i < v1.length; i++) {
      if (v1[i] !== v2[i]) {
        return false;
      }
    }
    return true;
  }
}
