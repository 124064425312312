<mat-sidenav-container>
  <mat-sidenav class="menu" mode="over" [(opened)]="menuOpened" *ngIf="showMenu">
    <div #menuContainer class="menuContainer">
      <mat-toolbar color="primary">
        <mat-toolbar-row>
          <button mat-icon-button (click)="onSideBackClick()">
            <mat-icon>arrow_back</mat-icon>
          </button>
          <mat-icon class="mat-icon-logo" svgIcon="assets:logo_short_financial_white"></mat-icon>
          <button mat-icon-button class="notifications">
            <mat-icon>notifications_none</mat-icon>
          </button>
        </mat-toolbar-row>
        <mat-toolbar-row *ngIf="user">
          <button mat-icon-button>
            <mat-icon svgIcon="user-circle"></mat-icon>
          </button>
          <div class="user">
            <h4 class="name">{{ (user | async)?.name }}</h4>
            <small class="identifier">{{ (user | async)?.identifier }}</small>
          </div>
        </mat-toolbar-row>
      </mat-toolbar>
      <ng-content select="[menu]"></ng-content>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <section class="bg" [class.fillPrimary]="fillPrimary" [class.offline]="!(onlineStatusService.$online | async)"
      (swiperight)="menuOpened = true">
      <mat-toolbar id="actionbar" color="primary" class="hiddenOnPrint">
        <button *ngIf="!searchVisible && showMenu" mat-icon-button (click)="toggleMenu()">
          <mat-icon>menu</mat-icon>
        </button>
        <button *ngIf="!showMenu && showBack" mat-icon-button (click)="onGoBackClick()">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-icon *ngIf="!searchVisible" [routerLink]="homePath" class="mat-icon-logo cursor-pointer"
          svgIcon="assets:logo_short_financial_white"></mat-icon>
        <h1 *ngIf="pageTitle && !searchVisible" class="pageTitle">| {{ pageTitle }}</h1>
        <ng-content select="[pageTitle]"></ng-content>
        <app-search-box *ngIf="showSearch" backIcon="arrow_back" [placeholder]="searchPlaceholder"
          [showUnderline]="true" (searchDebounce)="onSearchChange($event)" (clear)="onSearchClear()">
        </app-search-box>
        <ng-content select="[actions]"></ng-content>
      </mat-toolbar>
      <div class="body">
        <ng-content></ng-content>
      </div>
      <div class="offline-notification">Você está sem conexão com a internet</div>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>