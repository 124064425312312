import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Actives, Filter, PageData, ReadonlyRepository, SortOrder } from '@financial/arch';
import { RequiredHandler } from '@financial/common-utils';
import { BehaviorSubject, combineLatest, interval, Subscription } from 'rxjs';
import {
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  map
} from 'rxjs/operators';
import { LoadQueue } from '../shared';

const DEFAULT_DEBOUNCE_TIME = 500;
const DEFAULT_MIN_QUERY_LENGTH = 3;

interface QueryData {
  query: string;
  filters: Filter[];
  sorts: SortOrder[];
  showAll: boolean;
  page: PageData;
  ignore: string[];
}

@Component({
  selector: 'chips-data-selector',
  templateUrl: './chips-data-selector.component.html',
  styleUrls: ['./chips-data-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsDataSelectorComponent<T>
  extends RequiredHandler
  implements OnInit, ControlValueAccessor {
  @ContentChild('itemView', { static: false }) itemView: TemplateRef<any>;
  @ViewChild('input', { static: true }) input: ElementRef;
  @ViewChild('autocomplete', { static: false }) autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: false })
  trigger: MatAutocompleteTrigger;

  @Input() label: string;
  @Input() selectable: boolean = true;
  @Input() removable: boolean = true;
  @Input() disabled: boolean;
  @Input() invalid: boolean;
  @Input() repository: ReadonlyRepository<any, T>;
  @Input() minQueryLength = DEFAULT_MIN_QUERY_LENGTH;
  @Input() debounceTime = DEFAULT_DEBOUNCE_TIME;
  @Input() noLimitResults = false;
  @Input() lazyLoading = true;
  @Input() trackBy = 'id';
  @Output() valueChange = new EventEmitter<T[]>();
  @Output() loadFail = new EventEmitter<Error>();
  private _maxResults = 10;
  private _display = 'name';
  private onTouched: () => void = null;
  private onChangeSubscription: Subscription = null;

  innerValue: T[] = [];

  /* Internal */
  private querySubject = new BehaviorSubject<string>('');
  private filtersSubject = new BehaviorSubject<Filter[]>([]);
  private sortSubject = new BehaviorSubject<SortOrder[]>([]);
  private showAllSubject = new BehaviorSubject<boolean>(false);
  private pageSubject = new BehaviorSubject<PageData>(
    new PageData(0, this.maxResults)
  );
  private ignoreSubject = new BehaviorSubject<string[]>([]);

  private readonly emptyQueryData = {
    query: '',
    filters: [],
    sorts: [],
    showAll: false,
    page: new PageData(0, this.maxResults),
    ignore: [],
  };

  private loadSubject = new BehaviorSubject<QueryData>(this.emptyQueryData);
  private loadQueue = new LoadQueue();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  filteredData: T[] = [];

  @Input() displayWith: (s: T) => string = (s) =>
    s ? `${this.display in s ? (s as any)[this.display] : s}` : '';

  @Input() trackByFn: (index: number, entity: any) => any = (
    index: number,
    entity: any
  ) => (this.trackBy in entity ? entity[this.trackBy] : entity);

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private changeDetector: ChangeDetectorRef
  ) {
    super(ngControl);
    super.setControlValueAccessor(this);
    combineLatest([
      this.querySubject.pipe(
        map((q) => q || ''),
        map((q) => q.trim()),
        distinctUntilChanged(),
        debounce(() => interval(this.debounceTime))
      ),
      this.filtersSubject.pipe(distinctUntilChanged()),
      this.sortSubject.pipe(distinctUntilChanged()),
      this.showAllSubject.pipe(debounceTime(100), distinctUntilChanged()),
      this.pageSubject.pipe(distinctUntilChanged()),
      this.ignoreSubject.pipe(distinctUntilChanged()),
    ])
      .pipe(
        map(([query, filters, sorts, showAll, page, ignore]) => {
          return {
            query: query,
            filters: filters,
            sorts: sorts,
            showAll: showAll,
            page: page,
            ignore: ignore,
          };
        }),
        distinctUntilChanged()
      )
      .subscribe((queryData) => this.loadSubject.next(queryData));
  }

  ngOnInit(): void {
    this.loadSubject
      .pipe(
        filter((data) => Boolean(data) && Boolean(this.repository)),
        debounceTime(300),
        distinctUntilChanged((obj, anotherObj) =>
          this.isQueryDataEquals(obj, anotherObj)
        )
      )
      .subscribe((data) => {
        this.refresh(data);
      });
    this.valueChange.subscribe((value: T[]) => {
      this.ignoreSubject.next(
        value.map((v) => v['id']).filter((v) => this.isNotEmpty(v))
      );
    });
  }

  get placeholder(): string {
    return `${this.label}...`
  }

  get display(): string {
    return this._display;
  }

  @Input()
  set display(value: string) {
    this._display = value;
  }

  get entities(): T[] {
    return this.innerValue;
  }

  @Input()
  set entities(entities: T[]) {
    if (this.innerValue !== entities) {
      this.innerValue = entities;
      this.propagateValueChange();
    }
  }

  @Input()
  set filters(filters: Filter[]) {
    this.filtersSubject.next(filters);
  }

  @Input()
  set sorts(sorts: SortOrder[]) {
    this.sortSubject.next(sorts);
  }

  @Input()
  set maxResults(maxResults: number) {
    this._maxResults = maxResults;
    this.pageSubject.next(
      new PageData(this.pageSubject.value.first, this.maxResults)
    );
  }

  get maxResults(): number {
    return this._maxResults;
  }

  onKeyup(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = (input.value || '').trim();
    this.querySubject.next(value);
  }

  onFocus(event: FocusEvent) {
    this.togglePanel();
  }

  onBlur(event: FocusEvent) {
    if (this.onTouched) {
      this.onTouched();
    }
    this.clearInputValue();
    this.clearEntityList();
    if (!this.autocomplete.isOpen) {
      this.showAllSubject.next(false);
    }
  }

  onAdd(event: MatChipInputEvent): void {
    this.clearInputValue();
    this.clearEntityList();
  }

  onRemove(entity: T): void {
    const index = this.entities.indexOf(entity);

    if (index >= 0) {
      this.entities.splice(index, 1);
      this.propagateValueChange();
    }
  }

  onSelected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    this.pushValue(value);
  }

  writeValue(obj: T[]): void {
    this.entities = obj ?? [];
  }

  registerOnChange(fn: any): void {
    if (this.onChangeSubscription) {
      this.onChangeSubscription.unsubscribe();
    }
    this.onChangeSubscription = this.valueChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  togglePanel(evt?: MouseEvent) {
    evt?.stopPropagation();
    if (!this.autocomplete.isOpen) {
      this.showAllSubject.next(true);
    }

    setTimeout(() => {
      if (!this.autocomplete.isOpen) {
        this.trigger.openPanel();
      } else {
        this.trigger.closePanel();
      }
    }, 10);
  }

  onScrollDown(event: any) {
    if (this.lazyLoading) {
      this.loadNextPage();
    }
  }

  dragAndDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.entities, event.previousIndex, event.currentIndex);
    this.propagateValueChange();
  }

  private isNotEmpty(string: string): boolean {
    return string && string.trim() && string.trim().length > 0;
  }

  private clearInputValue() {
    this.input.nativeElement.value = '';
  }

  private clearEntityList() {
    this.querySubject.next(null);
  }

  private propagateValueChange() {
    this.valueChange.emit(this.entities);
  }

  private pushValue(entity: T) {
    this.entities.push(entity);
    this.clearInputValue();
    this.clearEntityList();
    this.propagateValueChange();
  }

  private async loadNextPage() {
    const queryData = this.loadSubject.value;
    queryData.page = queryData.page.next;
    this.runNetworkOp(
      async () =>
      (this.filteredData = [
        ...this.filteredData,
        ...(await this.fetchPage(queryData)),
      ])
    );
  }

  private async refresh(queryData: QueryData = this.loadSubject.value) {
    this.runNetworkOp(async () => {
      if (this.autocomplete && this.autocomplete.panel) {
        this.autocomplete.panel.nativeElement.scrollTo(0, 0);
      }
      if (
        queryData.showAll ||
        (queryData.query && queryData.query.length >= this.minQueryLength)
      ) {
        if (this.maxResults > 0 && !this.noLimitResults) {
          this.filteredData = await this.fetchPage(queryData);
        } else {
          this.filteredData = await this.fetchAll(queryData);
        }
      } else {
        this.filteredData = [];
      }
    });
  }

  private async fetchPage(queryData: QueryData = this.loadSubject.value): Promise<any[]> {
    return await this.repository
      .page(queryData.page, queryData.query, queryData.filters, queryData.sorts, Actives.TRUE, queryData.ignore)
      .toPromise();
  }

  private async fetchAll(queryData: QueryData = this.loadSubject.value): Promise<any[]> {
    return await this.repository
      .list(queryData.query, queryData.filters, queryData.sorts, Actives.TRUE, queryData.ignore)
      .toPromise();
  }

  private async runNetworkOp<T>(action: () => Promise<T>): Promise<T> {
    const load = this.loadQueue.enqueue();
    try {
      this.changeDetector.detectChanges();
      const result = await action();
      this.loadFail.emit(null);
      return result;
    } catch (e) {
      this.loadFail.emit(e);
    } finally {
      this.loadQueue.markFinished(load);
      this.changeDetector.markForCheck();
    }
  }

  private isQueryDataEquals(obj: QueryData, anotherObj: QueryData): boolean {
    return (
      obj &&
      anotherObj &&
      obj.query === anotherObj.query &&
      obj.showAll === anotherObj.showAll &&
      (obj.filters === anotherObj.filters ||
        this.isArrayEqual(obj.filters, anotherObj.filters)) &&
      (obj.sorts === anotherObj.sorts ||
        this.isArrayEqual(obj.sorts, anotherObj.sorts)) &&
      (obj.page === anotherObj.page || obj.page.isEqual(anotherObj.page)) &&
      (obj.ignore === anotherObj.ignore ||
        this.isArrayEqual(obj.ignore, anotherObj.ignore))
    );
  }

  private isArrayEqual(v1: any[], v2: any[]) {
    if (v1.length !== v2.length) {
      return false;
    }
    for (let i = 0; i < v1.length; i++) {
      if (v1[i] !== v2[i]) {
        return false;
      }
    }
    return true;
  }
}
