<mat-form-field>
  <mat-label><ng-content></ng-content></mat-label>
  <input
    matInput
    inputNumber
    [options]="options"
    [(ngModel)]="value"
    [disabled]="disabled"
    [readonly]="readonly"
    [required]="required"
    (ngModelChange)="propagateValueChange()"
  />
  <mat-icon matSuffix svgIcon="percent"></mat-icon>
</mat-form-field>
