import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeRegister, SharedThirdPartyModule } from '@financial/shared-third-party';
import { faEye, faEyeSlash, faPercent } from '@fortawesome/free-solid-svg-icons';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AsyncActionDirective } from './async-action/async-action.directive';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { FocusedDirective } from './autofocus/focused.directive';
import { BarcodeDirective } from './barcode/barcode.directive';
import { ChipsDataSelectorComponent } from './chips-data-selector/chips-data-selector.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { CurrencyMaskConfig } from './currency-mask/currency-mask.config';
import { CurrencyMaskModule } from './currency-mask/currency-mask.module';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { DataSelectorDialogComponent } from './data-selector-dialog';
import { DataSelectorComponent } from './data-selector/data-selector.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { EntityCardDirective } from './entity-cards/entity-card.directive';
import { EntityCardsComponent } from './entity-cards/entity-cards.component';
import { EntityCrudDetailsContentDirective } from './entity-crud/entity-crud-details-content.directive';
import { EntityCrudDialogComponent } from './entity-crud/entity-crud-dialog/entity-crud-dialog.component';
import { EntityCrudListDirective } from './entity-crud/entity-crud-list.directive';
import { EntityCrudComponent } from './entity-crud/entity-crud.component';
import { EntityDeleteDialogComponent } from './entity-crud/entity-delete-dialog/entity-delete-dialog.component';
import { EntityFabDialComponent } from './entity-fab-dial/entity-fab-dial.component';
import { EntityListPerspectiveComponent } from './entity-list-perspective/entity-list-perspective.component';
import { EntityTableComponent } from './entity-table/entity-table.component';
import { ExpansionFieldsetComponent } from './expansion-fieldset/expansion-fieldset.component';
import { InputNumberModule } from './input-number/input-number.module';
import { InputTextDialogComponent } from './input-text-dialog/input-text-dialog.component';
import { LoadingOverlayComponent } from './loading-overlay/loading-overlay.component';
import { KzMaskDirective } from './mask/kz-mask.directive';
import { NumberMaskPipe } from './mask/mask-pipe.pipe';
import { MaxValueDirective } from './max-value/max-value.directive';
import { PasswordVisibilityToggleComponent } from './password-visibility-toggle/password-visibility-toggle.component';
import { PercentInputComponent } from './percent-input/percent-input.component';
import { PipesModule } from './pipes';
import { SearchBoxComponent } from './search-box/search-box.component';
import { SwipeTabDirective } from './swipe-tab/swipe-tab.directive';
import { TemporalEntityCardsComponent } from './temporal-entity-cards/temporal-entity-cards.component';

export const DefaultCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: false
};

const COMPONENTS = [
  BarcodeDirective,
  KzMaskDirective,
  AutofocusDirective,
  FocusedDirective,
  DataSelectorComponent,
  EntityTableComponent,
  EntityCrudComponent,
  EntityCrudListDirective,
  EntityCrudDialogComponent,
  EntityCrudDetailsContentDirective,
  EntityDeleteDialogComponent,
  EntityFabDialComponent,
  EntityCardsComponent,
  EntityCardDirective,
  EntityListPerspectiveComponent,
  NumberMaskPipe,
  SwipeTabDirective,
  DateRangePickerComponent,
  LoadingOverlayComponent,
  TemporalEntityCardsComponent,
  ConfirmDialogComponent,
  MaxValueDirective,
  PercentInputComponent,
  CustomDatePickerComponent,
  ExpansionFieldsetComponent,
  AsyncActionDirective,
  InputTextDialogComponent,
  PasswordVisibilityToggleComponent,
  DataSelectorDialogComponent,
  SearchBoxComponent,
  ChipsDataSelectorComponent
];

const MODULES = [PipesModule, CurrencyMaskModule, InputNumberModule];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedThirdPartyModule,
    InfiniteScrollModule,
    DragDropModule,
    ...MODULES
  ],
  declarations: [...COMPONENTS],
  entryComponents: [
    EntityCrudDialogComponent,
    ConfirmDialogComponent,
    EntityDeleteDialogComponent,
    InputTextDialogComponent,
    DataSelectorDialogComponent
  ],
  exports: [...COMPONENTS, ...MODULES]
})
export class CommonComponentsModule {
  constructor(registry: FontAwesomeRegister) {
    registry.registerAll(faPercent, faEye, faEyeSlash);
  }
}
