import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartOfAccount, ChartOfAccountSummary } from './chart-of-account';

@Injectable({ providedIn: 'root' })
export class ChartOfAccountsRepository extends RepositoryImpl<ChartOfAccountSummary, ChartOfAccount> {

  constructor(api: ApiService) {
    super(api.root.path('chartOfAccounts'), ChartOfAccountSummary, ChartOfAccount);
  }

  listRootNodes(): Observable<ChartOfAccount[]> {
    return this.api
      .path('root-nodes')
      .getJson<any[]>()
      .pipe(map((records) => records.map(ChartOfAccount.fromJson)));
  }

  listChildNodes(chartOfAccount: ChartOfAccount): Observable<ChartOfAccount[]> {
    return this.api
      .path(chartOfAccount.id)
      .path('child-nodes')
      .getJson<any[]>()
      .pipe(map((records) => records.map(ChartOfAccount.fromJson)));
  }

  countChildNodes(id: string): Observable<number> {
    return this.api.path(id).path('child-nodes').path('size').getJson<number>();
  }

  hasNodes(chartOfAccount: ChartOfAccount): Observable<boolean> {
    return this.api
      .path(chartOfAccount.id)
      .path('child-nodes')
      .path('size')
      .getJson<number>()
      .pipe(map((size) => size > 0));
  }

}
