import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Employee, EmployeeSummary } from './employee';

@Injectable({ providedIn: 'root' })
export class EmployeesRepository extends RepositoryImpl<EmployeeSummary, Employee> {
  constructor(api: ApiService) {
    super(api.root.path('employees'), EmployeeSummary, Employee);
  }
}
