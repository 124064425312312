import { Injectable } from '@angular/core';
import { ApiService, TemporalRepositoryImpl } from '@financial/arch';
import { ClientsBalance } from './clients-balance';

@Injectable({ providedIn: 'root' })
export class ClientsBalanceRepository extends TemporalRepositoryImpl<ClientsBalance, ClientsBalance> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/clientsTotalBalance'), ClientsBalance, ClientsBalance);
  }

}
