import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { DateInterval, Functionality, RefreshablePage } from '@financial/arch';
import { EntityCrudComponent, EntityCrudType, NumericFilterDescription, TextualFilterDescription } from '@financial/common-components';
import { ProvidersBalance, ProvidersBalanceRepository, PROVIDERS_BALANCE } from '@financial/domain';
import * as moment from 'moment';

@Component({
  selector: 'app-providers-balance',
  templateUrl: './providers-balance.component.html',
  styleUrls: ['./providers-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvidersBalanceComponent extends RefreshablePage {

  static readonly PAGE_NAME: string = "Balanço de Fornecedores";

  @ViewChild(EntityCrudComponent, { static: true }) crud: EntityCrudComponent;

  allowedFilters = [
    new TextualFilterDescription('name', 'Nome'),
    new NumericFilterDescription('cpf', 'CPF'),
  ];

  entityTypes: EntityCrudType[] = [
    {
      type: 'ProvidersBalance',
      creator: () => new ProvidersBalance(),
      title: 'ProvidersBalance',
      shortTitle: 'ProvidersBalance'
    }
  ];

  functionality: Functionality = PROVIDERS_BALANCE;

  constructor(
    public repository: ProvidersBalanceRepository,
  ) {
    super();
  }

  get pageName() {
    return ProvidersBalanceComponent.PAGE_NAME;
  }

  hasUnsavedState(): boolean {
    return this.crud.hasUnsavedState();
  }

  private parseDate(date: Date): string {
    const m = moment.utc(date);
    return `${m.format('YYYY-MM-DD')}T${m.format('HH:mm:ss')}Z`;
  }
  public parseString(id: string, interval: DateInterval): string {
    return `${id}--${this.parseDate(interval.begin)}--${this.parseDate(interval.end)}`;
  }

}
