import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TopTenIncomes } from '@financial/domain';
import * as Chart from 'chart.js';
import { TopTenIncomesService } from './top-ten-incomes.service';

@Component({
  selector: 'app-top-ten-incomes',
  templateUrl: './top-ten-incomes.component.html',
  styleUrls: ['./top-ten-incomes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopTenIncomesComponent implements OnInit {

  topTenIncomes: TopTenIncomes[] = [];
  selectedIncome: TopTenIncomes = null;
  incomesGraph: Chart = null;
  incomesChartLabels: string[] = [];
  incomesChartData: number[] = [];

  constructor(
    private topTenIncomesService: TopTenIncomesService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {

    this.topTenIncomesService.list().subscribe(
      result => {
        this.topTenIncomes = result;
        this.selectedIncome = result[0];
        result.forEach(dto => {
          this.incomesChartLabels.push(dto.client);
          this.incomesChartData.push(dto.value);
        });
      },
      error => console.log(error),
      () => this.loadIncomesGraph()
    );

  }

  loadIncomesGraph() {
    this.incomesGraph = new Chart('topTenIncomes', {
      type: 'doughnut',
      data: {
        labels: this.incomesChartLabels,
        datasets: [{
          data: this.incomesChartData,
          backgroundColor: [
            '#2196F3',
            '#009688',
            '#4CAF50',
            '#8BC34A',
            '#CDDC39',
            '#FFEB3B',
            '#FFC107',
            '#FF9800',
            '#FF5722',
            '#F44336',
          ]
        }],
      },
      options: {
        onHover: (event) => {
          var element: any = this.incomesGraph.getElementAtEvent(event);
          if (element.length > 0) {
            this.selectedIncome = this.topTenIncomes[element[0]._index];
            this.changeDetection.markForCheck();
          };
        },
        responsive: true,
        legend: {
          display: false
        },
        title: {
          display: false
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 70
      }
    });
    this.changeDetection.markForCheck();
  }

}