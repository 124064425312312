<app-layout [pageTitle]="pageName" [showSearch]="true" (searchChange)="list.search($event)">
  <section actions>
    <button mat-icon-button (click)="crud.drawerRight.toggle()">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>
  <entity-crud #crud [functionality]="functionality" [repository]="repository" (entityChange)="list.invalidate()"
    [entityTypes]="entityTypes" [showDefaultFab]="false">
    <ep-temporal-entity-cards #list [repository]="repository" [perspective]="perspective.value">
      <mat-card *entityCard="let entity" (click)="crud.openEntity(parseString(entity.provider.id, list.interval))">

        <mat-card-header>
          <div matCardAvatar class="icon-avatar primary-bg">
            <mat-icon svgIcon="user-circle"></mat-icon>
          </div>
          <mat-card-title>
            <ng-container> {{ entity.provider.name }} </ng-container>
          </mat-card-title>
          <mat-card-subtitle>Fornecedor</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
          <section>
            <mat-icon svgIcon="money-bill"></mat-icon>
            {{ entity.pendingExpenses | currency: 'BRL' }} de despesas em aberto
          </section>
          <section>
            <mat-icon>monetization_on</mat-icon>
            {{ entity.totalBalance | currency: 'BRL' }} de pagamentos realizados
          </section>
        </mat-card-content>

      </mat-card>
    </ep-temporal-entity-cards>

    <section drawerRight>
      <entity-list-perspective #perspective [allowedFilters]="allowedFilters">
      </entity-list-perspective>
    </section>

    <app-providers-balance-details *entityCrudDetailsContent="let crud" [state]="crud.state" [(entity)]="crud.entity">
    </app-providers-balance-details>
  </entity-crud>
</app-layout>