import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { BehaviorSubject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('searchAnimation', [
      transition(':enter', [
        style({ 'max-width': '0%' }),
        animate('150ms ease-in', style({ 'max-width': '100%' }))
      ]),
      transition(':leave', [
        animate('150ms ease-in', style({ 'max-width': '0%' }))
      ])
    ])
  ]
})
export class SearchBoxComponent implements OnInit {
  private _searchVisible = false;
  private searchListener = new BehaviorSubject<string>("")

  @Input() backIcon = "arrow_back";
  @Input() searchIcon = "search";
  @Input() placeholder = "Procurar";
  @Input() showUnderline = true;
  @Input() debounceTime = 300;
  @Output() searchDebounce = new EventEmitter<string>();
  @Output() clear = new EventEmitter<boolean>();
  @ViewChild(MatInput) input: MatInput;


  constructor() { }

  ngOnInit(): void {
    this.searchListener.asObservable().pipe(
      distinctUntilChanged(),
      debounce(() => timer(this.debounceTime)),
    ).subscribe(v => this.searchDebounce.emit(v))
  }

  @Input()
  set searchVisible(value: boolean) {
    this._searchVisible = value;
    if (!this.searchVisible) {
      this.clearSearch();
    }
  }

  get searchVisible() {
    return this._searchVisible;
  }

  toggleSearch() {
    this.searchVisible = !this.searchVisible;
  }

  openSearch() {
    this.searchVisible = true;
  }

  closeSearch() {
    this.searchVisible = false;
  }

  clearSearch() {
    this.input.value = "";
    this.searchDebounce.emit(this.input.value)
  }

  onSearchValueChange() {
    this.searchListener.next(this.input.value)
  }

}
