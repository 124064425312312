<mat-card>
  <mat-card-content>
    <mat-card-title>10 Maiores Receitas</mat-card-title>
    <div class="chart-container">
      <canvas id="topTenIncomes"></canvas>      
      <div class="chart-text" *ngIf="topTenIncomes && topTenIncomes.length > 0 && selectedIncome">
        <h2>{{ selectedIncome.value | currency: 'BRL' }}</h2>
        <small>{{ selectedIncome.client }}</small>
        <p>{{ selectedIncome.unpaidIncomesPercentage }}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>