import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { RecaptchaModule, ReCaptchaV3Service } from 'ng-recaptcha';
import { ChartsModule } from 'ng2-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MomentModule } from 'ngx-moment';
import { MaterialModule } from './material/material.module';

const DATETIME_PICKER = [MatDatetimepickerModule, MatNativeDatetimeModule, MatMomentDatetimeModule];

const MODULES = [
  MaterialModule,
  MomentModule,
  LoadingBarModule,
  FontAwesomeModule,
  ChartsModule,
  InfiniteScrollModule,
  RecaptchaModule,
  ...DATETIME_PICKER
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  exports: MODULES,
  providers: [ReCaptchaV3Service]
})
export class SharedThirdPartyModule {}
