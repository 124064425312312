export class PageData {
  constructor(public readonly first = 0, public readonly rows = -1) {}

  get enabled(): boolean {
    return this.rows > 0;
  }

  get next(): PageData {
    return new PageData(this.rows + this.first + 1, this.rows);
  }

  isEqual(anotherObj: PageData): boolean {
    return anotherObj && this.first === anotherObj.first && this.rows === anotherObj.rows;
  }
}
