import { LayoutBaseModule } from '../base/layout-base.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultSharedModule } from '@financial/default-shared';
import { LayoutfinancialComponent } from './layout-financial.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MenuComponent } from './menu/menu.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';

const COMPONENTS = [LayoutfinancialComponent, MainMenuComponent, MenuComponent, SubMenuComponent];

@NgModule({
  imports: [CommonModule, DefaultSharedModule, LayoutBaseModule],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class LayoutfinancialModule {}
