import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MonthPipe } from '@financial/common-components';
import { CashFlowByPeriod } from '@financial/domain';
import * as Chart from 'chart.js';
import { CashFlowByPeriodService } from './cash-flow-by-period.service';

@Component({
  selector: 'app-cash-flow-by-period',
  templateUrl: './cash-flow-by-period.component.html',
  styleUrls: ['./cash-flow-by-period.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CashFlowByPeriodComponent implements OnInit {

  cashFlow: CashFlowByPeriod[] = [];
  cashFlowGraph: Chart = null;
  cashFlowChartLabels: string[] = [];
  incomesChartData: number[] = [];
  expensesChartData: number[] = [];
  balanceChartData: number[] = [];

  monthPipe: MonthPipe = new MonthPipe();

  constructor(
    private cashFlowByPeriodService: CashFlowByPeriodService,
    private changeDetection: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.cashFlowByPeriodService.list().subscribe(
      result => {
        this.cashFlow = result;
        result.forEach(dto => {
          this.cashFlowChartLabels.push(this.monthPipe.transform(dto.month));
          this.incomesChartData.push(dto.incomes);
          this.expensesChartData.push(dto.expenses);
          this.balanceChartData.push(dto.balance);
        });
      },
      error => console.log(error),
      () => this.loadCashFlowGraph()
    );
  }

  loadCashFlowGraph() {
    Chart.defaults.global.defaultFontFamily = "sans-serif";
    Chart.defaults.global.defaultFontSize = 14;

    this.cashFlowGraph = new Chart('cashFlow', {
      type: 'bar',
      data: {
        labels: this.cashFlowChartLabels,
        datasets: [
          {
            type: 'line',
            data: this.balanceChartData,
            borderWidth: 3,
            borderColor: '#9E9E9E',
            label: 'Balanço',
            fill: false
          },
          {
            data: this.expensesChartData,
            backgroundColor: '#f44336',
            barPercentage: 0.5,
            label: 'Despesas'
          },
          {
            data: this.incomesChartData,
            backgroundColor: '#4db6ac',
            barPercentage: 0.5,
            label: 'Receitas'
          }
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            usePointStyle: true
          }
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                return value.toLocaleString("pt-BR",{style:"currency", currency:"BRL"});
              }
            }
          }]
        }
      }
    });
    this.changeDetection.markForCheck();
  }

}