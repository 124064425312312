import { DomainEntity, SgvJson } from '@financial/arch';

export class Payment implements DomainEntity {

  constructor(
    public readonly id = "",
    public readonly date: Date = new Date(),
    public readonly paid: number = 0,
    public readonly interestArrears: number = 0,
    public readonly fine: number = 0,
    public readonly discount: number = 0,
    public readonly idAccount: number = 0,
    public readonly type: string = ''
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): Payment {
    return data ? SgvJson.from.simple(data, Payment) : null;
  }

  get isIncome(): boolean {
    return this.type == PaymentType.INCOME;
  }

  get isExpense(): boolean {
    return this.type == PaymentType.EXPENSE;
  }

  get isTransfer(): boolean {
    return this.type == PaymentType.TRANSFER;
  }
  
}

enum PaymentType {
  INCOME = "Income",
  EXPENSE = "Expense",
  TRANSFER = "Transfer"
}
