import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorResponse, HttpErrorHandler } from './http-error-handler';

const DEFAULT_MESSAGE = 'Ocorreu um erro não catalogado, tente novamente mais tarde!';

@Injectable({ providedIn: 'root' })
export class HttpErrorMessageHandler extends HttpErrorHandler {
  constructor(private snackBar: MatSnackBar) {
    super();
  }

  matches(error: ErrorResponse): boolean {
    return error.isClientError || error.isServerError;
  }

  handle(error: ErrorResponse): void {
    this.openSnackBar(error.message || DEFAULT_MESSAGE);
  }

  private openSnackBar(message: string, duration: number = 3000, action: string = null) {
    this.snackBar.open(message, action, {
      duration: duration,
      panelClass: ['httpError']
    });
  }
}
