import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Observable } from 'rxjs';
import { AccountingClosing, AccountingClosingSummary } from '../../accounting-closings';

@Injectable({ providedIn: 'root' })
export class AccountingClosingsReportRepository extends RepositoryImpl<AccountingClosing, AccountingClosingSummary> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/accoutingClosings'), AccountingClosing, AccountingClosingSummary);
  }
  
  getCsv(id: string): Observable<Blob> {
    return this.api.path(`${id}/file.csv`).getBlob();
  }
}
