<app-layout [pageTitle]="pageName" [showSearch]="false">

  <section actions class="filter">
    <button mat-icon-button (click)="drawerRight.toggle()">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>

  <mat-drawer-container [hasBackdrop]="false">

    <app-date-range-picker class="date-picker" [interval]="dateInterval" (intervalChange)="onIntervalChange($event)">
    </app-date-range-picker>

    <mat-card *ngIf="dataSource && dataSource.dtos.length > 0; else empty">
      <table mat-table [dataSource]="dataSource.dtos">

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef>Data</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{ element.date |
            date: 'dd/MM/yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Nome</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Natureza</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{ element.type |
            typeChartOfAccount }} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Descrição</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{ element.description
            }} </td>
        </ng-container>

        <ng-container matColumnDef="chartOfAccount">
          <th mat-header-cell *matHeaderCellDef>Plano de Conta</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{
            element.chartOfAccount }} </td>
        </ng-container>

        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef>Conta</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{ element.account }}
          </td>
        </ng-container>

        <ng-container matColumnDef="person">
          <th mat-header-cell *matHeaderCellDef>Origem/Destino</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{ 'chargeback': element.cancelled }"> {{ element.person }}
          </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Valor</th>
          <td mat-cell *matCellDef="let element"
            [ngClass]="{ 'chargeback': element.cancelled, 'credit' : element.type == 'Income', 'debit' : element.type == 'Expense' }">
            {{ element.value | currency: 'BRL' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <table mat-table [dataSource]="dataSource.total" class="footer">
        <ng-container matColumnDef="totalCredit">
          <th mat-header-cell *matHeaderCellDef>Total de Receitas</th>
          <td mat-cell *matCellDef="let element"> {{ element.totalCredit | currency: 'BRL' }} </td>
        </ng-container>

        <ng-container matColumnDef="totalDebit">
          <th mat-header-cell *matHeaderCellDef>Total de Despesas</th>
          <td mat-cell *matCellDef="let element"> {{ element.totalDebit | currency: 'BRL' }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="footerColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: footerColumns;"></tr>
      </table>
    </mat-card>

    <ng-template #empty>
      <h2 class="empty">Nenhum registro encontrado</h2>
    </ng-template>

    <mat-drawer #drawerRight position="end" [mode]="isMobileScreen ? 'over' : 'side'">
      <entity-list-perspective #perspective [allowedFilters]="allowedFilters" [showActives]="false">
      </entity-list-perspective>
    </mat-drawer>

  </mat-drawer-container>

</app-layout>