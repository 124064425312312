<app-layout [pageTitle]="pageName">

  <section actions class="filter">
    <button mat-icon-button (click)="drawerRight.toggle()">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>

  <div class="header">
    <app-date-range-picker [interval]="dateInterval" (intervalChange)="onIntervalChange($event)">
    </app-date-range-picker>

    <mat-checkbox class="hiddenOnPrint" [(ngModel)]="expandAll" (change)="onCheck($event.checked)">Expandir Tudo
    </mat-checkbox>
  </div>

  <mat-drawer-container [hasBackdrop]="false">

    <ng-container *ngIf="entities && entities.length > 0; else empty">
      <mat-accordion multi>
        <mat-expansion-panel class="info" *ngFor="let entity of entities">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ entity.costCenterName }}
            </mat-panel-title>
            <mat-panel-description>
              <small>{{ entity.totalValue | currency: 'BRL' }}</small>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <ng-template matExpansionPanelContent>
            <table>
              <thead>
                <tr>
                  <th [ngClass]="{ 'hiddenOnPrint' : column.hideOnPrint }" *ngFor="let column of columns">
                    {{ column.label }}
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let consolidatedPayment of entity.consolidatedPayments">
                  <td class="hiddenOnPrint type">
                    <mat-icon>{{ consolidatedPayment.chartOfAccount.type | apportionmentAnalysisIcon }}</mat-icon>
                  </td>
                  <td>
                    {{ consolidatedPayment.chartOfAccount.classificationAndName }}
                  </td>
                  <td>{{ consolidatedPayment.payment.date | date:'dd/MM/yyyy' }}</td>
                  <td [style.color]="colorByType(consolidatedPayment.chartOfAccount.type)">
                    {{ consolidatedPayment.value | currency: 'BRL' }}
                  </td>
                  <td>{{ consolidatedPayment.payment.account }}</td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>

    <ng-template #empty>
      <h2 class="empty">Nenhum registro encontrado</h2>
    </ng-template>

    <mat-drawer #drawerRight position="end" [mode]="isMobileScreen ? 'over' : 'side'" class="hiddenOnPrint">
      <entity-list-perspective #perspective [allowedFilters]="allowedFilters" [showActives]="false">
      </entity-list-perspective>
    </mat-drawer>

  </mat-drawer-container>


</app-layout>