import { SgvJson } from "@financial/arch";

export class ApportionmentAnalysisPayment {

    constructor(
        public readonly date: Date = null,
        public readonly account: string = ''
    ) { }

    toJson() {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any) {
        return json ? SgvJson.from.simple(json, ApportionmentAnalysisPayment) : null;
    }

}