import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import {
  Documents,
  ForeignPersonDocuments, LegalEntityDocuments,
  NaturalPersonDocuments
} from '../core/documents';

export class ClientSummary implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public readonly name = '',
    public readonly cnp = ''
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): ClientSummary {
    return json ? SgvJson.from.simple(json, ClientSummary) : null;
  }
}

export class Client implements DomainEntity {
  constructor(
    readonly id: string = SgvId.gen(),
    public name: string = '',
    public documents: Documents = new NaturalPersonDocuments(),
    public birth: Date = null,
    public registrationDate: Date = new Date(),
    public readonly active = true
  ) { }

  get isNaturalPerson(): boolean {
    return this.documents && this.documents instanceof NaturalPersonDocuments;
  }

  get isLegalEntity(): boolean {
    return this.documents && this.documents instanceof LegalEntityDocuments;
  }

  get isForeignPerson(): boolean {
    return this.documents && this.documents instanceof ForeignPersonDocuments;
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      documents: this.documents ? this.documents.toJson() : null
    });
  }

  static naturalPerson(): Client {
    const entity = new Client();
    entity.documents = new NaturalPersonDocuments();
    return entity;
  }

  static legalEntity(): Client {
    const entity = new Client();
    entity.documents = new LegalEntityDocuments();
    return entity;
  }

  static foreignPerson(): Client {
    const entity = new Client();
    entity.documents = new ForeignPersonDocuments();
    return entity;
  }

  static fromJson(json: any): Client {
    return SgvJson.from.simple(json, Client, {
      documents: json.documents ? Documents.fromJson(json.documents) : null,
      registrationDate: json.registrationDate ? new Date(json.registrationDate) : null,
      birth: json.birth ? new Date(json.birth) : null
    });
  }
}
