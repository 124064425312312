<mat-card>
  <mat-card-content>
    <mat-card-title>10 Maiores Despesas</mat-card-title>
    <div class="chart-container">
      <canvas id="topTenExpenses"></canvas>      
      <div class="chart-text" *ngIf="topTenExpenses && topTenExpenses.length > 0 && selectedExpense">
        <h2>{{ selectedExpense.value | currency: 'BRL' }}</h2>
        <small>{{ selectedExpense.person }}</small>
        <p>{{ selectedExpense.unpaidExpensesPercentage }}</p>
      </div>
    </div>
  </mat-card-content>
</mat-card>