import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonPagesModule } from '@financial/common-pages';
import { DefaultSharedModule } from '@financial/default-shared';
import { LayoutModule } from '../layout/layout.module';
import { PipesModule } from './pipes/pipes.module';

const MODULES = [
  DefaultSharedModule,
  CommonPagesModule,
  LayoutModule,
  PipesModule
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  exports: MODULES
})
export class SharedModule { }
