import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '@financial/common-pages';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: { title: 'Financeiro - Página não encontrada' }
  }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
  initialNavigation: 'enabled'
});
