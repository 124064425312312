import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as conf from './auth.config';
import { ManagerToken } from './token';

export const AUTH_HEADER_NAME = 'Authorization';

const DEFAULT_AUTH_TOKEN_NAME = 'bearerToken';

const DEFAULT_MANAGER_AUTH_TOKEN_NAME = 'managerBearerToken';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private authTokenState = new ReplaySubject<string>();

  private authTokenKey = DEFAULT_AUTH_TOKEN_NAME;

  private currentAuthToken: string;

  constructor(@Inject(conf.AUTH_TOKEN_NAME) @Optional() authTokenName: string, @Inject(conf.TENANT) private _tenant: string) {
    this.authTokenKey = authTokenName || DEFAULT_AUTH_TOKEN_NAME;
    this.authTokenState.next((this.currentAuthToken = localStorage.getItem(this.authTokenKey)));
    this.authTokenState.subscribe(token => {
      this.currentAuthToken = token;
      if (token) {
        localStorage.setItem(this.authTokenKey, token);
      } else {
        localStorage.removeItem(this.authTokenKey);
      }
    });
  }

  get $currentToken(): Observable<string> {
    return this.authTokenState;
  }

  get currentToken(): string {
    return this.currentAuthToken;
  }

  get tenant(): string {
    return this._tenant;
  }

  get $hasToken(): Observable<boolean> {
    return this.authTokenState.pipe(map(s => (s ? true : false)));
  }

  get hasToken(): boolean {
    return this.currentAuthToken && this.currentAuthToken.length > 5;
  }

  publishLogin(token: string) {
    this.authTokenState.next(token);
  }

  publishLogout() {
    this.authTokenState.next(null);
  }

  get $headerValue(): Observable<string> {
    return this.$currentToken.pipe(map(token => this.toHeader(token)));
  }

  get headerValue(): string {
    return this.toHeader(this.currentToken);
  }

  private toHeader(token: string): string {
    return this.hasToken ? `Bearer ${token}` : '';
  }
}
