<mat-form-field>
  <mat-chip-list
    #chipList
    cdkDropList
    cdkDropListOrientation="horizontal"
    (cdkDropListDropped)="dragAndDrop($event)"
  >
    <mat-chip
      cdkDrag
      *ngFor="let entity of entities; trackBy: trackByFn"
      [selectable]="selectable"
      [removable]="removable"
      (removed)="onRemove(entity)"
      [disabled]="disabled"
    >
      {{ displayWith(entity) }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip>

    <input
      type="text"
      #input
      matInput
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      (keyup)="onKeyup($event)"
      (blur)="onBlur($event)"
      (focus)="onFocus($event)"
      [disabled]="disabled"
      [required]="required"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onAdd($event)"
    />
  </mat-chip-list>

  <mat-label>{{ label }}</mat-label>
  <mat-hint *ngIf="invalid" class="mat-error"> O {{ label }} é obrigatório </mat-hint>
  <button mat-button type="button" matSuffix [disabled]="disabled" (click)="togglePanel($event)">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-form-field>

<mat-autocomplete
  infiniteScroll
  #autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="onSelected($event)"
  [autoActiveFirstOption]="true"
  [infiniteScrollDisabled]="!lazyLoading"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300"
  [infiniteScrollContainer]="autocomplete.panel"
  [scrollWindow]="false"
  (scrolled)="onScrollDown($event)"
>
  <mat-option *ngFor="let entity of filteredData; trackBy: trackByFn" [value]="entity">
    <ng-template
      [ngTemplateOutlet]="itemView"
      [ngTemplateOutletContext]="{ $implicit: entity }"
    ></ng-template>
    <span *ngIf="!itemView"> {{ displayWith(entity) }} </span>
  </mat-option>
</mat-autocomplete>
