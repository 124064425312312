import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class ImagesService {
  constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.register('background');
    this.register('circle_logo_financial');
    this.register('logo_financial');
    this.register('logo_financial_black');
    this.register('logo_financial_white');
    this.register('logo_short_financial_white');
    this.register('logo_short_financial_black');
    this.register('logo_apcbrh_black');
  }

  private register(name: string, namespace = 'assets', path = `/assets/images/${name}.svg`): void {
    this.iconRegistry.addSvgIconInNamespace(
      namespace,
      name,
      this.domSanitizer.bypassSecurityTrustResourceUrl(path)
    );
  }
}
