import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export class DateInterval {
  private cache = { isWhole: {}, durationAs: {} };

  constructor(readonly begin: Date, readonly end: Date) {}

  durationAs(value: Moment.unitOfTime.DurationAs) {
    if (this.cache.durationAs[value]) {
      return this.cache.durationAs[value];
    }
    return (this.cache.durationAs[value] = moment.range(this.begin, this.end).duration(value));
  }

  isWhole(value: Moment.unitOfTime.Base) {
    if (this.cache.isWhole[value]) {
      return this.cache.isWhole[value];
    }
    return (this.cache.isWhole[value] =
      moment(this.begin)
        .startOf(value)
        .isSame(this.begin) &&
      moment(this.begin)
        .endOf(value)
        .isSame(this.end));
  }

  get isWholeDay() {
    return this.isWhole('day');
  }

  get isWholeMonth() {
    return this.isWhole('month');
  }

  get isWholeYear() {
    return this.isWhole('year');
  }

  get durationInMillis() {
    return this.durationAs('milliseconds');
  }

  get durationInHours() {
    return this.durationAs('hours');
  }

  get durationInDays() {
    return this.durationAs('days');
  }

  get durationInMonths() {
    return this.durationAs('months');
  }

  get durationInYears() {
    return this.durationAs('years');
  }

  get isFullDays() {
    return this.isStartOf(this.begin, 'day') && this.isEndOf(this.end, 'day');
  }

  get isFullWeek() {
    return this.isStartOf(this.begin, 'week') && this.isEndOf(this.end, 'week');
  }

  get isFullMonths() {
    return this.isStartOf(this.begin, 'month') && this.isEndOf(this.end, 'month');
  }

  get isFullYears() {
    return this.isStartOf(this.begin, 'year') && this.isEndOf(this.end, 'year');
  }

  next(): DateInterval {
    if (this.isWholeMonth) {
      return DateInterval.ofMonth(
        moment(this.begin)
          .add(1, 'month')
          .toDate()
      );
    } else if (this.isWholeYear) {
      return DateInterval.ofYear(
        moment(this.begin)
          .add(1, 'year')
          .toDate()
      );
    } else if (this.isFullMonths) {
      return DateInterval.of(
        moment(this.end)
          .add(1, 'months')
          .startOf('month')
          .toDate(),
        moment(this.end)
          .add(this.durationInMonths + 1, 'months')
          .endOf('month')
          .toDate()
      );
    } else {
      const begin = moment(this.end).add(1, 'milliseconds');
      return new DateInterval(
        begin.toDate(),
        begin
          .clone()
          .add(this.durationInMillis, 'milliseconds')
          .toDate()
      );
    }
  }

  previous(): DateInterval {
    if (this.isWholeMonth) {
      return DateInterval.ofMonth(
        moment(this.begin)
          .subtract(1, 'month')
          .toDate()
      );
    } else if (this.isWholeYear) {
      return DateInterval.ofYear(
        moment(this.begin)
          .subtract(1, 'year')
          .toDate()
      );
    } else if (this.isFullMonths) {
      return DateInterval.of(
        moment(this.begin)
          .subtract(this.durationInMonths + 1, 'months')
          .startOf('month')
          .toDate(),
        moment(this.begin)
          .subtract(1, 'months')
          .endOf('month')
          .toDate()
      );
    } else {
      const end = moment(this.begin).subtract(1, 'milliseconds');
      return new DateInterval(
        end
          .clone()
          .subtract(this.durationInMillis, 'milliseconds')
          .toDate(),
        end.toDate()
      );
    }
  }
  private isStartOf(date: Date, type: Moment.unitOfTime.Base) {
    return moment(date)
      .startOf(type)
      .isSame(date);
  }

  private isEndOf(date: Date, type: Moment.unitOfTime.Base) {
    return moment(date)
      .endOf(type)
      .isSame(date);
  }

  static ofToday(): DateInterval {
    return this.ofDay(new Date());
  }

  static ofDay(date: Date): DateInterval {
    return this.ofWhole(date, 'day');
  }

  static ofWeek(date: Date): DateInterval {
    return this.ofWhole(date, 'week');
  }

  static ofMonth(date: Date): DateInterval {
    return this.ofWhole(date, 'month');
  }

  static ofYear(date: Date): DateInterval {
    return this.ofWhole(date, 'year');
  }

  static of(begin: Date, end: Date): DateInterval {
    return new DateInterval(
      moment(begin)
        .startOf('day')
        .toDate(),
      moment(end)
        .endOf('day')
        .toDate()
    );
  }

  static ofWhole(date: Date, period: Moment.unitOfTime.Base): DateInterval {
    return new DateInterval(
      moment(date)
        .startOf(period)
        .toDate(),
      moment(date)
        .endOf(period)
        .toDate()
    );
  }
}
