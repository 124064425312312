import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'typeChartOfAccount'
})
export class ChartOfAccountPipe implements PipeTransform {

  transform(type: string): string {
    if(type) type = type.toLowerCase();

    switch (type) {
      case 'expense':
        return 'Débito';
      case 'income':
        return 'Crédito';
      case 'transfer':
        return 'Transferência';
    }

  }

}
