import { Injectable } from '@angular/core';
import { ApiPath, ApiService, DateInterval, Filter, SgvJson, UrlSearchParamsBuilder } from '@financial/arch';
import { ApportionmentAnalysis } from '@financial/domain';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApportionmentAnalysisService {

    private apiPath: ApiPath;

    constructor(api: ApiService) {
        this.apiPath = api.root.path('accountingClosings');
    }

    listByInterval(interval: DateInterval, filters: Filter[] = []): Observable<ApportionmentAnalysis[]> {
        const params = new UrlSearchParamsBuilder().filters(this.applyFilters(filters)).build();

        return this.apiPath.path('getByPeriod').path(this.parseInterval(interval))
            .getJson<ApportionmentAnalysis[]>({ params: params }).pipe(
                retry(3),
                map(json => SgvJson.from.array(json, ApportionmentAnalysis.fromJson))
            );
    }

    private parseDate(date: Date): string {
        const m = moment.utc(date);
        return `${m.format('YYYY-MM-DD')}T${m.format('HH:mm:ss')}Z`;
    }

    private parseInterval(interval: DateInterval): string {
        return `${this.parseDate(interval.begin)}--${this.parseDate(interval.end)}`;
    }

    private applyFilters(filters: Filter[]): Filter[] {
        return [...filters, ...(filters || [])];
    }

}
