import { Injectable } from "@angular/core";
import { ApiPath, ApiService } from "@financial/arch";
import { IncomesAndExpenses } from "@financial/domain";
import { Observable } from "rxjs";
import { retry } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class IncomesAndExpensesService {

    private apiPath: ApiPath;

    constructor(api: ApiService) {
        this.apiPath = api.root.path('reports').path('incomesAndExpensesByPeriod');
    }

    list(): Observable<IncomesAndExpenses[]> {
        return this.apiPath.getJson<IncomesAndExpenses[]>().pipe(retry(3));
    }

}
