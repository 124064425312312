import { SgvJson } from "@financial/arch";


export class ApportionmentAnalysisChartOfAccount {

    constructor(
        public readonly type: String = '',
        public readonly name: String = '',
        public readonly classification: String = ''
    ) { }

    get classificationAndName(): string {
        return `${this.classification} - ${this.name}`;
    }

    toJson() {
        return SgvJson.to.simple(this);
    }

    static fromJson(json: any) {
        return json ? SgvJson.from.simple(json, ApportionmentAnalysisChartOfAccount) : null;
    }

}