import { Injectable } from '@angular/core';
import { ApiService, TemporalRepositoryImpl } from '@financial/arch';
import { ProvidersBalance } from './providers-balance';

@Injectable({ providedIn: 'root' })
export class ProvidersBalanceRepository extends TemporalRepositoryImpl<ProvidersBalance, ProvidersBalance> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/providersTotalBalance'), ProvidersBalance, ProvidersBalance);
  }

}
