import { Injectable } from '@angular/core';
import { AuthService } from '@financial/arch';
import { Observable } from 'rxjs';
import { debounceTime, filter, flatMap, map, retry, shareReplay } from 'rxjs/operators';
import { User } from '../users';
import { CurrentInfoRepository } from './current-info.repository';

export interface Principal {
  readonly user: string;
  readonly tenant: string;
}

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  public readonly $user: Observable<User>;

  public readonly $principal: Observable<Principal>;

  public readonly $principalStr: Observable<string>;

  constructor(authService: AuthService, currentInfo: CurrentInfoRepository) {
    this.$user = authService.$hasToken.pipe(
      debounceTime(100),
      filter(hasToken => hasToken),
      flatMap(() => currentInfo.getUser()),
      shareReplay(1)
    );
    this.$principal = authService.$hasToken.pipe(
      debounceTime(100),
      filter(hasToken => hasToken),
      flatMap(_ => currentInfo.getPrincipal().pipe(retry(3))),
      shareReplay(1)
    );
    this.$principalStr = this.$principal.pipe(map(p => (p ? `${p.user}@${p.tenant}` : '')));
  }
}
