<mat-form-field>
  <input
    type="text"
    #input
    matInput
    [matAutocomplete]="auto"
    (keyup)="onKeyup($event)"
    (blur)="onBlur($event)"
    (focus)="onFocus($event)"
    [disabled]="disabled"
    [required]="required"
  />
  <mat-label>{{ label }}</mat-label>
  <mat-hint *ngIf="invalid" class="mat-error">O {{ label }} é obrigatório</mat-hint>
  <button mat-button type="button" matSuffix [disabled]="disabled" (click)="togglePanel($event)">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-form-field>
<mat-autocomplete
  infiniteScroll
  #autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayWith"
  (optionSelected)="onSelected($event)"
  [autoActiveFirstOption]="true"
  [infiniteScrollDisabled]="!lazyLoading"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="300"
  [infiniteScrollContainer]="autocomplete.panel"
  [scrollWindow]="false"
  (scrolled)="onScrollDown($event)"
>
  <mat-option *ngFor="let entity of filteredData; trackBy: trackByFn" [value]="entity">
    <ng-template
      [ngTemplateOutlet]="itemView"
      [ngTemplateOutletContext]="{ $implicit: entity }"
    ></ng-template>
    <span *ngIf="!itemView"> {{ displayWith(entity) }} </span>
  </mat-option>
</mat-autocomplete>
