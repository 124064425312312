<button mat-button class="arrow hiddenOnPrint" (click)="goPrevious()">
  <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<button mat-button class="value" [matMenuTriggerFor]="intervalOptionsMenu">
  {{ interval | dateInterval: (withTime ? 'datetime' : 'date') }}
</button>
<button mat-button class="arrow hiddenOnPrint" (click)="goNext()">
  <mat-icon>keyboard_arrow_right</mat-icon>
</button>
<mat-menu #intervalOptionsMenu="matMenu">
  <button *ngFor="let o of intervalOptions; trackBy: trackOptionBy" mat-menu-item (click)="selectOption(o)">
    {{ o.label }}
  </button>
</mat-menu>