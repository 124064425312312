<app-layout-base [fillPrimary]="fillPrimary" [pageTitle]="pageTitle" [showSearch]="showSearch"
  [searchPlaceholder]="searchPlaceholder" [showMenu]="showMenu" [showBack]="showBack" [homePath]="homePath"
  [user]="user" [onSideBackClick]="onSideBackClickFn" (searchChange)="searchChange.emit($event)">
  <ng-container menu>
    <app-main-menu *ngIf="!submenu" [@menuAnimation] [menu]="mainMenu" (submenuClick)="onSubmenuClick($event)">
    </app-main-menu>
    <app-sub-menu *ngIf="submenu" [@menuAnimation] [menu]="submenu"></app-sub-menu>
  </ng-container>

  <ng-container actions>
    <ng-content select="[actions]"></ng-content>
  </ng-container>

  <ng-container>
    <section class="printHeader showOnlyOnPrint">
      <mat-card>
        <mat-card-content>
          <mat-icon class="mat-icon-logo apcbrh" svgIcon="assets:logo_apcbrh_black"></mat-icon>
          <div class="text">
            <h4>ASSOCIAÇÃO PARANAENSE DE CRIADORES DE BOVINOS DA RAÇA HOLANDESA</h4>
            <h4> {{ pageTitle }}</h4>
          </div>
        </mat-card-content>
      </mat-card>
    </section>
  </ng-container>

  <ng-content></ng-content>
</app-layout-base>