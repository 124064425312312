<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <span>{{ data.content }}</span>
  <mat-form-field>
    <mat-label>{{ data.label }}</mat-label>
    <ng-container *ngIf="isTextArea; then textAreaTemplate; else inputTemplate"></ng-container>

    <ng-template #inputTemplate>
      <input
        matInput
        type="text"
        [formControl]="inputTextControl"
        [maxLength]="data.maxLength"
        cdkFocusInitial
      />
    </ng-template>

    <ng-template #textAreaTemplate>
      <textarea
        matInput
        type="text"
        [formControl]="inputTextControl"
        [maxLength]="data.maxLength"
        cdkFocusInitial
        matTextareaAutosize
        [matAutosizeMinRows]="data.minRows"
        [matAutosizeMaxRows]="data.maxRows"
      ></textarea>
    </ng-template>

    <mat-error *ngIf="inputTextControl.errors?.required">
      {{ data.label || 'Valor' }} é obrigatório.
    </mat-error>
    <mat-error *ngIf="inputTextControl.errors?.minlength">
      {{ data.label || 'Valor' }} deve ter pelo menos {{ data.minLength }} caracteres.
    </mat-error>
    <mat-error *ngIf="inputTextControl.errors?.maxlength">
      {{ data.label || 'Valor' }} deve no máximo {{ data.maxLength }} caracteres.
    </mat-error>
    <mat-error *ngIf="inputTextControl.errors?.pattern">
      {{ data.label || 'Valor' }} informado é inválido.
    </mat-error>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    type="button"
    color="accent"
    [mat-dialog-close]="inputTextControl.value"
    [disabled]="inputTextControl.invalid"
  >
    Confirmar
  </button>
  <button mat-button type="button" color="warn" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
