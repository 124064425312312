import { Component, OnInit } from '@angular/core';

import { ErrorType } from '../error-page/error-page.component';

@Component({
  selector: 'app-unknown-error',
  templateUrl: './unknown-error.component.html'
})
export class UnknownErrorComponent implements OnInit {
  defaultColor: ErrorType = ErrorType.Fail;
  constructor() {}

  ngOnInit() {}
}
