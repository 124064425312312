import { JsonEntity, SgvJson } from "@financial/arch";
import { Bank } from '../banks';

export class Agency implements JsonEntity {

  constructor(
    public number: number = 0,
    public digit: number = 0,
    public name: string = '',
    public bank: Bank = null
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): Agency {
    return SgvJson.from.simple(data, Agency, {
      bank: Bank.fromJson(data.bank)
    });
  }

}
