import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'month'
})
export class MonthPipe implements PipeTransform {

    transform(value: string): string {

        value ? value.toUpperCase() : ""

        switch (value) {
            case "JANUARY":
                return "Janeiro";
            case "FEBRUARY":
                return "Fevereiro";
            case "MARCH":
                return "Março";
            case "APRIL":
                return "Abril";
            case "MAY":
                return "Maio";
            case "JUNE":
                return "Junho";
            case "JULY":
                return "Julho";
            case "AUGUST":
                return "Agosto";
            case "SEPTEMBER":
                return "Setembro";
            case "OCTOBER":
                return "Outubro";
            case "NOVEMBER":
                return "Novembro";
            case "DECEMBER":
                return "Dezembro";
            default:
                return "Mês";
        }
    }

}