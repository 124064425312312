import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { Agency } from './agency';

export abstract class Account implements DomainEntity {
  readonly id: string = SgvId.gen();
  constructor(
    public name: string = '',
    public balance: number = 0,
    public type: string = ''
  ) { }

  get isBankAccount(): boolean {
    return this instanceof BankAccount;
  }

  get isSimpleAccount(): boolean {
    return this instanceof SimpleAccount;
  }

  readonly isActive = true;

  abstract toJson(): any;

  static fromJson(data: any): Account {
    switch (data.type) {
      case 'bank':
        return BankAccount.fromJson(data);
      case 'simple':
        return SimpleAccount.fromJson(data);
    }
  }
}

export class BankAccount extends Account {
  constructor(
    public number: number = 0,
    public digit: number = 0,
    public limit: number = 0,
    public agency: Agency = null
  ) {
    super('', 0, 'bank');
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(data: any): BankAccount {
    return SgvJson.from.simple(data, BankAccount, {
      number: data.number,
      digit: data.digit,
      limit: data.limit,
      agency: Agency.fromJson(data.agency)
    });
  }
}

export class SimpleAccount extends Account {
  constructor() {
    super('', 0, 'simple');
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }
  static fromJson(data: any): SimpleAccount {
    return SgvJson.from.simple(data, SimpleAccount);
  }
}
