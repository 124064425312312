import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPath, ApiService } from '@financial/arch';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { DateUtils } from '../../../../../common-utils/src/lib/date-utils/date-utils';
import { AccountingClosingType } from '../../accounting-closings';
import { ConsolidatedPayment } from '../../accounting-closings/consolidated-payments/consolidated-payment';

@Injectable({ providedIn: 'root' })
export class PaymentsRepository {
  private api: ApiPath;

  constructor(api: ApiService) {
    this.api = api.root.path('payments');
  }

  getConsolidatedPayments(begin: Date, end: Date, type: AccountingClosingType): Observable<ConsolidatedPayment[]> {
    const params = new HttpParams()
      .append('begin', DateUtils.toString(begin))
      .append('end', DateUtils.toString(end))
      .append('type', type.value);

    return this.api
      .path('consolidated')
      .getJson<ConsolidatedPayment[]>({ params: params })
      .pipe(
        map(r => r.map((j: any) => ConsolidatedPayment.fromJson(j))),
        retry(3)
      )
  }
}
