<form [formGroup]="form">
  <header>
    Perspectiva
  </header>

  <mat-accordion displayMode="flat" [multi]="true">
    <mat-expansion-panel *ngIf="allowedFilters.length > 0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>filter_list</mat-icon> Filtros ({{ value.filters.length }})
        </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="content" formArrayName="filters">
        <div *ngFor="let filter of filters.controls; let i = index" class="fieldGroup" [class.valid]="filter.valid"
          [formGroupName]="i">
          <div class="filterGroup">
            <mat-form-field>
              <mat-select placeholder="Campo" formControlName="filter" (selectionChange)="onFilterChange(filter)">
                <mat-option *ngFor="let allowedFilter of allowedFilters" [value]="allowedFilter">
                  {{ allowedFilter.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button tabindex="-1" (click)="removeFilter(i)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <div *ngIf="isText(filter)" class="valueGroup textValue">
            <mat-form-field class="operation auto-width">
              <mat-select formControlName="operation">
                <mat-option *ngFor="let op of operations.text" [value]="op.value">
                  {{ op.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="value">
              <input matInput placeholder="Valor" formControlName="value" />
            </mat-form-field>
          </div>

          <div *ngIf="isNumeric(filter)" class="valueGroup textValue">
            <mat-form-field class="operation auto-width">
              <mat-select formControlName="operation">
                <mat-option *ngFor="let op of operations.number" [value]="op.value">
                  {{ op.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="value">
              <input matInput type="number" placeholder="Valor" formControlName="value" />
            </mat-form-field>
          </div>

          <div *ngIf="isBoolean(filter)" class="valueGroup textValue">
            <mat-form-field class="value auto-width">
              <mat-select formControlName="value">
                <mat-option [value]="true"> Sim </mat-option>
                <mat-option [value]="false"> Não </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="isReference(filter)" class="valueGroup refValue">
            <mat-form-field class="operation auto-width">
              <mat-select formControlName="operation">
                <mat-option *ngFor="let op of operations.reference" [value]="op.value">
                  {{ op.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <app-data-selector formControlName="value" class="value auto-width"
              [repository]="filter.value.filter.repository" label="Selecione"
              [display]="filter.value.filter.displayWith"></app-data-selector>
          </div>

          <div *ngIf="isEnum(filter)" class="valueGroup textValue">
            <mat-form-field class="operation auto-width">
              <mat-select formControlName="operation">
                <mat-option *ngFor="let op of operations.enum" [value]="op.value">
                  {{ op.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="value auto-width">
              <mat-select formControlName="value">
                <mat-option *ngFor="let value of filter.value.filter.values" [value]="value.name">
                  {{ value.label || value.name || value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </section>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="allowedSorts.length > 0" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>sort</mat-icon> Ordenação ({{ value.sorts.length }})
        </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="content" formArrayName="sorts">
        <div *ngFor="let sort of sorts.controls; let i = index" class="fieldGroup" [class.valid]="sort.valid"
          [formGroupName]="i">
          <div class="sortGroup">
            <mat-form-field class="auto-width">
              <mat-select placeholder="Campo" formControlName="field">
                <mat-option *ngFor="let allowedSort of allowedSorts" [value]="allowedSort">
                  {{ allowedSort.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="sort?.value?.field" class="auto-width">
              <mat-select formControlName="value">
                <mat-option [value]="0"> Crescente </mat-option>
                <mat-option [value]="1"> Decrescente </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-icon-button tabindex="-1" (click)="removeSort(i)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </section>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="showSettings" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-icon>settings</mat-icon> Definições
        </mat-panel-title>
      </mat-expansion-panel-header>
      <section class="content" formGroupName="settings">
        <mat-form-field *ngIf="showActives">
          <mat-label>Exibir registros</mat-label>
          <select matNativeControl formControlName="actives">
            <option *ngFor="let option of activesOptions" [value]="option.value">
              {{ option.name }}
            </option>
          </select>
        </mat-form-field>
      </section>
    </mat-expansion-panel>
  </mat-accordion>
</form>