import { DomainEntity, SgvId, SgvJson } from '@financial/arch';

export class User implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public login = '',
    public name = '',
    public insertionDate = new Date(),
    public password = '',
    public readonly version = 0,
    public readonly isActive = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): User {
    return json
      ? SgvJson.from.simple(json, User)
      : null;
  }
}
