import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiPath } from '../api/api.service';
import { DateInterval } from '../domain';
import { JsonEntity } from '../domain/json-utils';
import { InterceptorConfig } from '../interceptors';
import { RequestMetadata, RequestMetadataUtils } from './../api/api.service';
import { Actives } from './search-options';
import { Filter } from './search-options/filter';
import { PageData } from './search-options/page-data';
import { SortOrder } from './search-options/sort-order';
import { UrlSearchParamsBuilder } from './search-options/url-search-params-builder';
import { TemporalReadonlyRepository } from './temporal-readonly-repository';

export class TemporalReadonlyRepositoryImpl<S extends JsonEntity, T extends JsonEntity>
  implements TemporalReadonlyRepository<S, T> {
  constructor(
    protected api: ApiPath,
    protected summaryType: { fromJson(json: any): S },
    protected type: { fromJson(json: any): T },
    protected filters?: Filter[],
    protected metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ) {}

  private applyFilter(filters: Filter[]): Filter[] {
    return [...filters, ...(this.filters || [])];
  }

  protected parseDate(date: Date): string {
    const m = moment.utc(date);
    return `${m.format('YYYY-MM-DD')}T${m.format('HH:mm:ss')}Z`;
  }

  protected parseInterval(interval: DateInterval): string {
    return `${this.parseDate(interval.begin)}--${this.parseDate(interval.end)}`;
  }

  list(
    interval: DateInterval,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<S[]> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .build();
    return this.api
      .path(this.parseInterval(interval))
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(r => r.map((j: any) => this.summaryType.fromJson(j))));
  }

  listFull(
    interval: DateInterval,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T[]> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .summary(false)
      .build();
    return this.api
      .path(this.parseInterval(interval))
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(r => r.map((j: any) => this.type.fromJson(j))));
  }

  page(
    interval: DateInterval,
    pageData: PageData,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<S[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .build();
    return this.api
      .path(this.parseInterval(interval))
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(r => r.map((j: any) => this.summaryType.fromJson(j))));
  }

  pageFull(
    interval: DateInterval,
    pageData: PageData,
    query: string = '',
    filters: Filter[] = [],
    sortOrders: SortOrder[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T[]> {
    const search = new UrlSearchParamsBuilder()
      .page(pageData)
      .query(query)
      .filters(this.applyFilter(filters))
      .sortOrders(sortOrders)
      .actives(actives)
      .summary(false)
      .build();
    return this.api
      .path(this.parseInterval(interval))
      .getJson<any[]>({
        params: search,
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(r => r.map((j: any) => this.type.fromJson(j))));
  }

  count(
    interval: DateInterval,
    query: string = '',
    filters: Filter[] = [],
    actives = Actives.TRUE,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<number> {
    const search = new UrlSearchParamsBuilder()
      .query(query)
      .filters(this.applyFilter(filters))
      .actives(actives)
      .build();
    return this.api
      .path(this.parseInterval(interval))
      .path('size')
      .getText({ params: search, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(
        map(r => r || '0'),
        map(text => Number.parseInt(text, 10))
      );
  }

  find(
    id: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<T> {
    return this.api
      .path(id)
      .getJson({
        metadata: RequestMetadataUtils.merge(metadata, this.metadata)
      })
      .pipe(map(r => this.type.fromJson(r)));
  }

  findSummary(
    id: string,
    metadata: RequestMetadata = { autoCatch: InterceptorConfig.AUTO }
  ): Observable<S> {
    const params = new UrlSearchParamsBuilder().summary(true).build();
    return this.api
      .path(id)
      .getJson({ params: params, metadata: RequestMetadataUtils.merge(metadata, this.metadata) })
      .pipe(map(r => this.summaryType.fromJson(r)));
  }
}
