import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { CostCenter } from './cost-center';

@Injectable({ providedIn: 'root' })
export class CostCentersRepository extends RepositoryImpl<CostCenter, CostCenter> {
  constructor(api: ApiService) {
    super(api.root.path('costCenters'), CostCenter, CostCenter);
  }
}
