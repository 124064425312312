export class SageLotType {
    public static DAILY = new SageLotType('D', 'Diário');
    public static MONTHLY = new SageLotType('M', 'Mensal');
    public static YEARLY = new SageLotType('A', 'Anual');
  
    public constructor(
      public readonly identifier: string, 
      public readonly description: string
    ) {}
  
    public static values(): SageLotType[] {
      return [
        SageLotType.DAILY,
        SageLotType.MONTHLY,
        SageLotType.YEARLY
      ];
    }
  
    public static get(identifier: string): SageLotType {
      const sageLotType = SageLotType.values().find(sageType => sageType.identifier === identifier);
      if (!sageLotType) {
        const err = new Error(`SageLotType ${identifier} is not mapped!`);
        console.error(err);
        throw err;
      }
      return sageLotType;
    }
  }
  