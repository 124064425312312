import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ApportionmentComponent } from '../financial/chart-of-accounts/apportionment/apportionment.component';
import { ApportionmentByCostCenterComponent } from './apportionment-by-cost-center/apportionment-by-cost-center.component';
import { CashFlowByPeriodComponent } from './cash-flow-by-period/cash-flow-by-period.component';
import { HomeComponent } from './home.component';
import { HomeRoutes } from './home.routes';
import { IncomesAndExpensesComponent } from './incomes-and-expenses/incomes-and-expenses.component';
import { IncomesVsExpensesComponent } from './incomes-vs-expenses/incomes-vs-expenses.component';
import { TopTenExpensesComponent } from './top-ten-expenses/top-ten-expenses.component';
import { TopTenIncomesComponent } from './top-ten-incomes/top-ten-incomes.component';

@NgModule({
  imports: [CommonModule, SharedModule, HomeRoutes],
  declarations: [
    HomeComponent,
    IncomesAndExpensesComponent,
    IncomesVsExpensesComponent,
    CashFlowByPeriodComponent,
    TopTenExpensesComponent,
    TopTenIncomesComponent,
    ApportionmentByCostCenterComponent
  ]
})
export class HomeModule { }
