
export class ApportionmentType {

  public static ACCOUNTS = new ApportionmentType('accounts', 'Contas');
  public static BANKS = new ApportionmentType('banks', 'Bancos');
  public static CLIENTS = new ApportionmentType('clients', 'Clientes');
  public static DESCRIPTIONS = new ApportionmentType('descriptions', 'Descrições');
  public static EMPLOYEES = new ApportionmentType('employees', 'Empregados');
  public static PROVIDERS = new ApportionmentType('providers', 'Fornecedores');

  private constructor(
    public readonly name: string,
    public readonly label: string
  ) { }

  public static values(): ApportionmentType[] {
    return [
      ApportionmentType.ACCOUNTS,
      ApportionmentType.BANKS,
      ApportionmentType.CLIENTS,
      ApportionmentType.DESCRIPTIONS,
      ApportionmentType.EMPLOYEES,
      ApportionmentType.PROVIDERS
    ];
  }

  public static valuesWithAllProperty(): ApportionmentType[] {
    return [
      ApportionmentType.BANKS,
      ApportionmentType.CLIENTS,
      ApportionmentType.EMPLOYEES,
      ApportionmentType.PROVIDERS
    ];
  }

  public static get(name: string): ApportionmentType {
    const type = ApportionmentType.values().find(v => v.name === name);
    if (!type) {
      const err = new Error(`${this.name} [${name}] is not mapped!`);
      console.error(err);
      throw err;
    }
    return type;
  }






}