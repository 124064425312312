import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { RequiredHandler } from '@financial/common-utils';
import { InputNumberConfig } from '@financial/common-components';

@Component({
  selector: 'app-percent-input',
  templateUrl: './percent-input.component.html',
  styleUrls: ['./percent-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PercentInputComponent extends RequiredHandler implements ControlValueAccessor {
  onChange: (value: any) => void;

  onTouch: (value: any) => void;

  @Output() valueChange = new EventEmitter<number>();

  @Input() readonly = false;

  @Input() disabled = false;

  private readonly defaultOptions: Partial<InputNumberConfig> = {
    maxValue: 100,
    minValue: 0,
    precision: 0,
    allowNegative: false,
    nullable: true
  };

  private _options: Partial<InputNumberConfig> = this.defaultOptions;

  private _value = 0;

  constructor(private changeDetector: ChangeDetectorRef, @Optional() @Self() ngControl: NgControl) {
    super(ngControl);
    super.setControlValueAccessor(this);
  }

  set value(value: number) {
    value > this.defaultOptions.maxValue 
      ? this._value = this.defaultOptions.maxValue 
      : this._value = value;
    if (this.onChange) {
      this.onChange(this._value);
    }
    this.changeDetector.markForCheck();
  }

  get value(): number {
    return this._value;
  }

  @Input()
  set options(options: Partial<InputNumberConfig>) {
    this._options = (<any>Object).assign({}, this.defaultOptions, options);
    this.changeDetector.markForCheck();
  }

  get options(): Partial<InputNumberConfig> {
    return this._options;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  propagateValueChange() {
    this.valueChange.emit(this.value);
  }
}
