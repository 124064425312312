import { Pipe, PipeTransform } from '@angular/core';
import { ApportionmentType } from '../../features/financial/chart-of-accounts/apportionment/apportionment-type';
@Pipe({
    name: 'apportionmentType'
})
export class ApportionmentTypePipe implements PipeTransform {

    transform(value: any, ...args: any[]) {
        return ApportionmentType.get(value).label;
    }

}