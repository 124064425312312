export class Filter {
  private static parseOp(v: string): FilterOp {
    switch (v) {
      case 'equals':
        return FilterOp.EQ;
      case 'contains':
        return FilterOp.CONT;
      case 'in':
        return FilterOp.IN;
      case 'startsWith':
        return FilterOp.SW;
      case 'endsWith':
        return FilterOp.EW;
      default:
        return null;
    }
  }

  constructor(
    public readonly field: string,
    public readonly operator = FilterOp.EQ,
    public readonly value: any
  ) { }

  get operatorStr(): string {
    return FilterOp[this.operator];
  }

  get valueStr(): string {
    if (this.value?.hasOwnProperty('id')) {
      return this.value.id;
    } else if (this.value != null) {
      return `${this.value}`;
    } else {
      return '';
    }
  }
}
export enum FilterOp {
  EQ,
  NE,
  GT,
  GTE,
  LT,
  LTE,
  SW,
  EW,
  CONT,
  NCONT,
  IN,
  NEX
}
