<div *ngIf="searchVisible" [@searchAnimation] class="search-box-open">
  <input
    matInput
    autofocus
    class="search-input"
    [placeholder]="placeholder"
    (keyup)="onSearchValueChange()"
  />
  <button mat-icon-button class="icon" (click)="closeSearch()">
    <mat-icon>{{ backIcon }}</mat-icon>
  </button>
</div>
<div *ngIf="!searchVisible" [@searchAnimation] class="search-box-closed">
  <button mat-icon-button class="icon" (click)="openSearch()">
    <mat-icon>{{ searchIcon }}</mat-icon>
  </button>
</div>
