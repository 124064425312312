import { SgvJson } from '@financial/arch';
import { Account } from '../accounts/account';
import { Bank } from '../banks/bank';
import { Client } from '../clients/client';
import { Employee } from '../employees/employee';
import { Provider } from '../providers/provider';

export class SettingEventSummary {

  constructor(
    public readonly allSettingNames: string[] = [],
    public readonly type = ''
  ) { }

  get isAccounts(): boolean {
    return this.type === "accounts";
  }

  get isBanks(): boolean {
    return this.type === "banks";
  }

  get isEmployees(): boolean {
    return this.type === "employees";
  }

  get isProviders(): boolean {
    return this.type === "providers";
  }

  get isClients(): boolean {
    return this.type === "clients";
  }

  get isDescriptions(): boolean {
    return this.type === "descriptions";
  }


  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): SettingEventSummary {
    return json ? SgvJson.from.simple(json, SettingEventSummary) : null;
  }
}

export abstract class SettingEvent {

  constructor(
    public type: string = ''
  ) { }

  get isAccounts(): boolean {
    return this instanceof OfAccounts;
  }

  get isBanks(): boolean {
    return this instanceof OfBanks;
  }

  get isEmployees(): boolean {
    return this instanceof OfEmployees;
  }

  get isProviders(): boolean {
    return this instanceof OfProviders;
  }

  get isClients(): boolean {
    return this instanceof OfClients;
  }

  get isDescriptions(): boolean {
    return this instanceof OfDescriptions;
  }

  abstract toJson(): any;

  static fromJson(data: any): SettingEvent {
    switch (data.type) {
      case 'accounts':
        return OfAccounts.fromJson(data);
      case 'banks':
        return OfBanks.fromJson(data);
      case 'clients':
        return OfClients.fromJson(data);
      case 'descriptions':
        return OfDescriptions.fromJson(data);
      case 'employees':
        return OfEmployees.fromJson(data);
      case 'providers':
        return OfProviders.fromJson(data);
    }
  }

  static fromForm(data: any): SettingEvent {
    switch (data.type) {
      case 'accounts':
        return OfAccounts.fromForm(data);
      case 'banks':
        return OfBanks.fromForm(data);
      case 'clients':
        return OfClients.fromForm(data);
      case 'descriptions':
        return OfDescriptions.fromForm(data);
      case 'employees':
        return OfEmployees.fromForm(data);
      case 'providers':
        return OfProviders.fromForm(data);
    }
  }
}


export class OfAccounts extends SettingEvent {

  constructor(
    public accounts: Account[] = []
  ) {
    super('accounts');
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      accounts: SgvJson.to.array(this.accounts),
    });
  }

  static fromJson(data: any): OfAccounts {
    return SgvJson.from.simple(data, OfAccounts, {
      accounts: SgvJson.from.array(data.accounts, Account.fromJson)
    });
  }

  static fromForm(data: any): OfAccounts {
    return new OfAccounts(
      SgvJson.from.array(data.data, Account.fromJson)
    );
  }
}

export class OfBanks extends SettingEvent {

  constructor(
    public all: boolean = false,
    public banks: Bank[] = []
  ) {
    super('banks');
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      banks: SgvJson.to.array(this.banks),
    });
  }

  static fromJson(data: any): OfBanks {
    return SgvJson.from.simple(data, OfBanks, {
      banks: SgvJson.from.array(data.banks, Bank.fromJson)
    });
  }

  static fromForm(data: any): OfBanks {
    return new OfBanks(
      data.all,
      SgvJson.from.array(data.data, Bank.fromJson)
    );
  }
}

export class OfClients extends SettingEvent {

  constructor(
    public all: boolean = false,
    public clients: Client[] = []
  ) {
    super('clients');
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      clients: SgvJson.to.array(this.clients),
    });
  }

  static fromJson(data: any): OfClients {
    return SgvJson.from.simple(data, OfClients, {
      clients: SgvJson.from.array(data.clients, Client.fromJson)
    });
  }

  static fromForm(data: any): OfClients {
    return new OfClients(
      data.all,
      SgvJson.from.array(data.data, Client.fromJson)
    );
  }
}

export class OfDescriptions extends SettingEvent {

  constructor(
    public descriptions: string[] = []
  ) {
    super('descriptions');
  }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): OfDescriptions {
    return SgvJson.from.simple(data, OfDescriptions);
  }

  static fromForm(data: any): OfDescriptions {
    return new OfDescriptions(
      data.data
    );
  }
}

export class OfEmployees extends SettingEvent {

  constructor(
    public all: boolean = false,
    public employees: Employee[] = []
  ) {
    super('employees');
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      employees: SgvJson.to.array(this.employees),
    });
  }

  static fromJson(data: any): OfEmployees {
    return SgvJson.from.simple(data, OfEmployees, {
      employees: SgvJson.from.array(data.employees, Employee.fromJson)
    });
  }

  static fromForm(data: any): OfEmployees {
    return new OfEmployees(
      data.all,
      SgvJson.from.array(data.data, Employee.fromJson)
    );
  }
}

export class OfProviders extends SettingEvent {

  constructor(
    public all: boolean = false,
    public providers: Provider[] = []
  ) {
    super('providers');
  }

  toJson(): any {
    return SgvJson.to.simple(this, {
      providers: SgvJson.to.array(this.providers),
    });
  }

  static fromJson(data: any): OfProviders {
    return SgvJson.from.simple(data, OfProviders, {
      providers: SgvJson.from.array(data.providers, Provider.fromJson)
    });
  }

  static fromForm(data: any): OfProviders {
    return new OfProviders(
      data.all,
      SgvJson.from.array(data.data, Provider.fromJson)
    );
  }
}
