import { DomainEntity, JsonEntity, SgvId, SgvJson } from '@financial/arch';
import { SageLotType } from './sage-lot-type';
import { SageOrigin } from './sage-origin';

export class SageLot implements DomainEntity {

  constructor(
    public readonly id: string = SgvId.gen(),
    public readonly type: SageLotType = SageLotType.MONTHLY,
    public readonly origin: SageOrigin = SageOrigin.LIF,
    public readonly date: Date = new Date(),
    public readonly description: string = "",
    public readonly isActive: boolean = true
  ) {}

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: 'MONTHLY',
      origin: 'LIF'
    })
  }

  static fromJson(json: any): SageLot {
    return json ? SgvJson.from.simple(json, SageLot, {
        type: json.type ? SageLotType.get(json.type) : null,
        origin: json.origin ? SageOrigin.get(json.origin) : null
    }) : null;
  }
}
