import { Inject, Injectable, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { ErrorResponse, HttpErrorHandler } from './http-error-handler';

export const INACTIVE_PATH = 'inactivePath';

const DEFAULT_INACTIVE_PATH = '/inactive';

const CONSTRAINT_VIOLATION = 'eprecise.financial.core.security.InactiveEnterpriseViolation';

@Injectable({ providedIn: 'root' })
export class InactiveEnterpriseErrorHandler extends HttpErrorHandler {
  readonly inactivePath: string;

  constructor(
    @Inject(INACTIVE_PATH) @Optional() inactivePath: string,
    private authService: AuthService,
    private router: Router
  ) {
    super();
    this.inactivePath = inactivePath || DEFAULT_INACTIVE_PATH;
  }

  matches(error: ErrorResponse): boolean {
    return error.constraintName ? error.constraintName.includes(CONSTRAINT_VIOLATION) : false;
  }

  handle(error: ErrorResponse): void {
    this.authService.publishLogout();
    this.router.navigate([this.inactivePath]);
  }
}
