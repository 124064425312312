import { DomainEntity, SgvId, SgvJson } from '@financial/arch';
import { ConsolidatedPayment } from './consolidated-payments/consolidated-payment';

export class AccountingClosingSummary implements DomainEntity {

  constructor(
    public readonly id = SgvId.gen(),
    public readonly date: Date = new Date(),
    public readonly begin: Date = new Date(),
    public readonly end: Date = new Date(),
    public readonly type: AccountingClosingType = null,
    public readonly consolidatedPayments: ConsolidatedPayment[] = [],
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      type: this.type.value,
      consolidatedPayments: SgvJson.to.array(this.consolidatedPayments),
    });
  }

  static fromJson(data: any): AccountingClosingSummary {
    return data ? SgvJson.from.simple(data, AccountingClosingSummary, {
      type: AccountingClosingType.from(data.type),
      consolidatedPayments: SgvJson.from.array(data.consolidatedPayments, ConsolidatedPayment.fromJson)
    }) : null;
  }

  get expensePaid(): number {
    return this.consolidatedPayments.filter(i => i.payment.isExpense).map(i => i.payment.paid).reduce((a, b) => a + b, 0.0);
  }

  get incomePaid(): number {
    return this.consolidatedPayments.filter(i => i.payment.isIncome).map(i => i.payment.paid).reduce((a, b) => a + b, 0.0);
  }

  get transferPaid(): number {
    return this.consolidatedPayments.filter(i => i.payment.isTransfer).map(i => i.payment.paid).reduce((a, b) => a + b, 0.0);
  }
}

export class AccountingClosing implements DomainEntity {

  constructor(
    public id = SgvId.gen(),
    public begin: Date = new Date(),
    public end: Date = new Date(),
    public type: AccountingClosingType = AccountingClosingType.INCOME,
    public consolidatedPayments: ConsolidatedPayment[] = [],
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this, {
      payments: SgvJson.to.array(this.consolidatedPayments),
      type: this.type.value
    });
  }

  static fromJson(data: any): AccountingClosing {
    return data ? SgvJson.from.simple(data, AccountingClosing, {
      begin: new Date(data.begin),
      end: new Date(data.end),
      type: AccountingClosingType.from(data.json),
      consolidatedPayments: SgvJson.from.array(data.payments, ConsolidatedPayment.fromJson)
    }) : null;
  }
}

export class AccountingClosingType {
  public static INCOME = new AccountingClosingType('INCOME', 'Receita');
  public static EXPENSE = new AccountingClosingType('EXPENSE', 'Despesa');
  public static TRANSFER = new AccountingClosingType('TRANSFER', 'Transferência');

  constructor(
    public readonly value: string,
    public readonly description: string
  ) { }

  public static values(): AccountingClosingType[]{
    return [
      AccountingClosingType.INCOME, 
      AccountingClosingType.EXPENSE, 
      AccountingClosingType.TRANSFER
    ];
  }

  public static from(value: String): AccountingClosingType {
    if(!value){
      return null;
    }

    switch (value) {
      case AccountingClosingType.INCOME.value:
        return AccountingClosingType.INCOME;
      case AccountingClosingType.EXPENSE.value:
        return AccountingClosingType.EXPENSE;
      case AccountingClosingType.TRANSFER.value:
        return AccountingClosingType.TRANSFER;
    }
    return null;
  }
}
