import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { ChartOfAccount, Client } from "@financial/domain";
import { ClientsAccountingPayment } from "./clients-accounting-payment";

export class ClientsAccountingBalance implements DomainEntity {

  constructor(
    public readonly id = SgvId.gen(),
    public readonly client : Client = new Client(),
    public readonly chartOfAccount: ChartOfAccount = null,
    public readonly payments: ClientsAccountingPayment[] = [],
    public readonly totalDebits: number = 0,
    public readonly totalCredits: number = 0,
    public readonly totalBalance: number = 0,
    public readonly previousBalance: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): ClientsAccountingBalance {
    return data ? SgvJson.from.simple(data, ClientsAccountingBalance) : null;
  }
}
