import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { DateUtils } from '@financial/common-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountingClosing, AccountingClosingSummary } from './accounting-closing';
import { ApportionmentAnalysis } from './apportionment-analysis/apportionment-analysis';

@Injectable({ providedIn: 'root' })
export class AccountingClosingsRepository extends RepositoryImpl<AccountingClosingSummary, AccountingClosing> {

  constructor(api: ApiService) {
    super(api.root.path('accountingClosings'), AccountingClosingSummary, AccountingClosing);
  }

  getByPeriod(date: Date): Observable<ApportionmentAnalysis> {
    return this.api.path('getByPeriod').path(DateUtils.toString(date, 'YYYY-MM-DD')).getJson<any[]>().pipe(map(ApportionmentAnalysis.fromJson));
  }

}
