import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FeaturesRoutes } from './features.routes';
import { HomeModule } from './home/home.module';
import { LoginModule } from './login/login.module';

@NgModule({
  imports: [CommonModule, LoginModule, HomeModule, FeaturesRoutes],
  declarations: []
})
export class FeaturesModule {}
