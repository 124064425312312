import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Client, ClientSummary } from './client';

@Injectable({ providedIn: 'root' })
export class ClientsRepository extends RepositoryImpl<ClientSummary, Client> {
  constructor(api: ApiService) {
    super(api.root.path('clients'), ClientSummary, Client);
  }
}
