import { DomainEntity, SgvId, SgvJson } from "@financial/arch";
import { Client, Expense, Income, Payment } from "@financial/domain";

export class ClientsBalance implements DomainEntity {

  constructor(
    public readonly id = SgvId.gen(),
    public readonly client : Client = new Client(),
    public readonly incomes: Income[] = [],
    public readonly expenses: Expense[] = [],
    public readonly payments: Payment[] = [],
    public readonly pendingIncomes: number = 0,
    public readonly pendingExpenses: number = 0,
    public readonly totalBalance: number = 0,
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(data: any): ClientsBalance {
    return data ? SgvJson.from.simple(data, ClientsBalance) : null;
  }
}
