export class SageOrigin {
    public static LIF = new SageOrigin('LIF', 'LIVROS FISCAIS');
    public static FPG = new SageOrigin('FPG', 'FOLHA DE PAGAMENTOS');
    public static OUT = new SageOrigin('OUT', 'OUTROS');
  
    public constructor(
      public readonly identifier: String, 
      public readonly description: String
    ) {}
  
    public static values(): SageOrigin[] {
      return [
        SageOrigin.LIF,
        SageOrigin.FPG,
        SageOrigin.OUT
      ];
    }
  
    public static get(identifier: string): SageOrigin {
      const sageOrigin = SageOrigin.values().find(origin => origin.identifier === identifier);
      if (!sageOrigin) {
        const err = new Error(`SageOrigin ${identifier} is not mapped!`);
        console.error(err);
        throw err;
      }
      return sageOrigin;
    }
  }
  