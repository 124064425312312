import { Action, Functionality } from '@financial/arch';
export class Feature implements Functionality {
  private readonly actionMap = new Map((this.actions.map(a => [a.id, a]) as unknown) as ReadonlyArray<[string, Action]>);

  constructor(public readonly id: string, public readonly actions: Action[] = []) { }

  getActionById(id: string) {
    return this.actionMap.get(id);
  }

  toJson() {
    return this.id;
  }

  public static fromJson(id: string) {
    return ALL_MAP.get(id);
  }

  public static all() {
    return ALL;
  }
}


export const ACCOUNTS = new Feature('ACCOUNTS');

export const CLIENTS = new Feature('CLIENTS');

export const EMPLOYEES = new Feature('EMPLOYEES');

export const PROVIDERS = new Feature('PROVIDERS');

export const BANKS = new Feature('BANKS');

export const COST_CENTERS = new Feature('COST_CENTERS');

export const CHART_OF_ACCOUNTS = new Feature('CHART_OF_ACCOUNTS');

export const CLIENTS_BALANCE = new Feature('CLIENTS_BALANCE');

export const PROVIDERS_BALANCE = new Feature('PROVIDERS_BALANCE');

export const CLIENTS_ACCOUNTING_BALANCE = new Feature('CLIENTS_ACCOUNTING_BALANCE');

export const PROVIDERS_ACCOUNTING_BALANCE = new Feature('PROVIDERS_ACCOUNTING_BALANCE');

export const ACCOUNTS_BALANCE = new Feature('ACCOUNTS_BALANCE');

export const ACCOUNTING_CLOSINGS = new Feature('ACCOUNTING_CLOSINGS');

export const APPORTIONMENT_ANALYSIS = new Feature('APPORTIONMENT_ANALYSIS');

export const CASH_FLOW = new Feature('CASH_FLOW');

export const ALL = [
  ACCOUNTS,
  CLIENTS,
  EMPLOYEES,
  PROVIDERS,
  BANKS,
  COST_CENTERS,
  CHART_OF_ACCOUNTS,
  ACCOUNTING_CLOSINGS,
  APPORTIONMENT_ANALYSIS,
  CLIENTS_BALANCE,
  PROVIDERS_BALANCE,
  CLIENTS_ACCOUNTING_BALANCE,
  PROVIDERS_ACCOUNTING_BALANCE,
  ACCOUNTS_BALANCE,
  ACCOUNTING_CLOSINGS,
  CASH_FLOW
];

const ALL_MAP = new Map((ALL.map(f => [f.id, f]) as unknown) as ReadonlyArray<[string, Functionality]>);
