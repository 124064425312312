<app-layout [pageTitle]="pageName" [showSearch]="true" (searchChange)="list.search($event)">
  <section actions>
    <button mat-icon-button (click)="crud.drawerRight.toggle()">
      <mat-icon svgIcon="filter"></mat-icon>
    </button>
  </section>

  <entity-crud #crud [functionality]="functionality" [repository]="repository" (entityChange)="list.invalidate()"
    [entityTypes]="entityTypes" [showDefaultFab]="false">
    <mat-accordion>
      <ep-temporal-entity-cards #list [repository]="repository" [perspective]="perspective.value">
        <mat-expansion-panel *entityCard="let entity">
          <mat-expansion-panel-header class="no-wrap">
            <mat-panel-title>
              Conta: {{ entity.chartOfAccount.classification }} - {{ entity.provider.name }}
            </mat-panel-title>
            <mat-panel-description>
              <small>Saldo anterior: {{ entity.previousBalance | currency: 'BRL' }}</small>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <fieldset class="paymentData first">
              <section *ngFor="let payment of entity.payments">
                <mat-form-field>
                  <mat-label>Data</mat-label>
                  <input matInput disabled value="{{ payment.date | date: 'dd/MM/yyyy' }}" readonly />
                </mat-form-field>
                <mat-form-field *ngIf="payment.debit > 0">
                  <mat-label>Débito</mat-label>
                  <input class="debit" matInput disabled value="{{ payment.debit | currency: 'BRL' }}" readonly />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Saldo</mat-label>
                  <input class="total" matInput disabled value="{{ payment.balance | currency: 'BRL'}}" readonly />
                </mat-form-field>
              </section>
            </fieldset>
            <fieldset class="paymentData">
              <legend>Total da Conta</legend>
              <section>
                <mat-form-field>
                  <mat-label>Total de Débitos</mat-label>
                  <input class="total" matInput disabled value="{{ entity.totalDebits | currency: 'BRL'}}" readonly />
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Saldo Total</mat-label>
                  <input class="total" matInput disabled value="{{ entity.totalBalance | currency: 'BRL'}}" readonly />
                </mat-form-field>
              </section>
            </fieldset>
          </ng-template>
        </mat-expansion-panel>
      </ep-temporal-entity-cards>
    </mat-accordion>

    <section drawerRight>
      <entity-list-perspective #perspective [allowedFilters]="allowedFilters">
      </entity-list-perspective>
    </section>

  </entity-crud>

</app-layout>