import { DomainEntity, SgvId, SgvJson } from '@financial/arch';

export class Bank implements DomainEntity {
  constructor(
    public readonly id = SgvId.gen(),
    public name = '',
    public number = '',
    public readonly active = true
  ) { }

  toJson(): any {
    return SgvJson.to.simple(this);
  }

  static fromJson(json: any): Bank {
    return json
      ? SgvJson.from.simple(json, Bank)
      : null;
  }
}
