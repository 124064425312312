import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl, TemporalRepositoryImpl } from '@financial/arch';
import { SageLot } from './sage-lot';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SageLotRepository extends RepositoryImpl<SageLot, SageLot> {

  constructor(api: ApiService) {
    super(api.root.path('/reports/sage'), SageLot, SageLot);
  }

  generateReport(accountingClosingId: string, sageLot: SageLot): Observable<SageLot> {
    return this.api.path(accountingClosingId).postJson(sageLot);
  }

  getFileFromLotId(lotId: string): Observable<Blob> {
    return this.api.path(`lot/${lotId}/file.txt`).getBlob();
  }

}
