import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  ApiModule,
  API_ROOT_URL,
  TENANT,
  ArchModule,
  AuthModule,
  AUTH_TOKEN_NAME,
  AUTO_CATCH_CONFIG,
  HttpErrorHandler,
  HttpErrorInterceptor,
  HttpErrorMessageHandler,
  InactiveEnterpriseErrorHandler,
  InterceptorConfig,
  LOGIN_ROUTE
} from '@financial/arch';
import { AssetsModule } from '@financial/assets';
import { CURRENCY_MASK_CONFIG, DefaultCurrencyMaskConfig } from '@financial/common-components';
import { DomainModule } from '@financial/domain';
import { DefaultMatPaginatorIntl, FontAwesomeRegister } from '@financial/shared-third-party';
import {
  faArrowLeft,
  faBriefcase,
  faCalculator,
  faCalendarAlt,
  faCheck,
  faChevronRight,
  faFile,
  faFileExport,
  faFileImport,
  faHandshake,
  faHandsHelping,
  faPeopleCarry,
  faPrint,
  faTractor,
  faTruck,
  faUserCircle,
  faUserTie
} from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../environments/environment';
import { MAIN_MENU } from '../features/features-catalog';

@NgModule({
  declarations: [],
  imports: [CommonModule, DomainModule, ApiModule, AuthModule, ArchModule, AssetsModule],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: DefaultCurrencyMaskConfig },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: AUTH_TOKEN_NAME, useValue: 'financial.authToken' },
    { provide: LOGIN_ROUTE, useValue: '/login' },
    { provide: API_ROOT_URL, useValue: environment.apiRoot },
    { provide: TENANT, useValue: environment.tenant },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    { provide: MatPaginatorIntl, useClass: DefaultMatPaginatorIntl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: AUTO_CATCH_CONFIG, useValue: InterceptorConfig.INTERCEPT },
    { provide: HttpErrorHandler, useClass: HttpErrorMessageHandler, multi: true },
    { provide: HttpErrorHandler, useClass: InactiveEnterpriseErrorHandler, multi: true }
  ]
})
export class CoreModule {
  constructor(registry: FontAwesomeRegister) {
    registry.registerAll(...MAIN_MENU.icons);
    registry.registerAll(
      faArrowLeft,
      faCheck,
      faChevronRight,
      faUserCircle,
      faPrint,
      faFileImport,
      faFileExport,
      faTractor,
      faFile,
      faBriefcase,
      faCalendarAlt,
      faUserTie,
      faCalculator,
      faHandsHelping,
      faTruck,
      faPeopleCarry,
      faHandshake
    );
  }
}
