import { Injectable } from '@angular/core';
import { ApiService, RepositoryImpl } from '@financial/arch';
import { Account } from './account';

@Injectable({ providedIn: 'root' })
export class AccountsRepository extends RepositoryImpl<Account, Account> {
  constructor(api: ApiService) {
    super(api.root.path('accounts'), Account, Account);
  }
}
