import { TemplateRef, ViewContainerRef, Directive, Input } from "@angular/core";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[entityCrudList]'
})
export class EntityCrudListDirective {
  @Input() entityCrudList: string;

  constructor(public readonly templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }


}
