import { Injectable } from "@angular/core";
import { ApiPath, ApiService } from "@financial/arch";
import { TopTenExpenses } from "@financial/domain";
import { Observable } from "rxjs";
import { retry } from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class TopTenExpensesService {

    private apiPath: ApiPath;

    constructor(api: ApiService) {
        this.apiPath = api.root.path('reports').path('topTenExpensesByPeriod');
    }

    list(): Observable<TopTenExpenses[]> {
        return this.apiPath.getJson<TopTenExpenses[]>().pipe(retry(3));
    }

}
